import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useGetSingleOrder from "../../hooks/orders/usegetsingleorder";
import OrderList from "../../component/order/orderview/cryptotransactionlist";


export default function UserOverView() {
	const {getSIngleOrder, data, loading} = useGetSingleOrder()
	const [index, setIndex] = useState(0)
	const {id, user} = useParams()

	const handleGetOrder = async () =>{
		await getSIngleOrder(id, user)
	}


	useEffect(()=>{
		handleGetOrder()
	},[])


	return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm mt-4">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h4 className="page-title cus-page-title">User Overview</h4>
                    <div className="nk-block-des">
                      <p>An overview of user account.</p>
                    </div>
                  </div>
                  {/* .nk-block-head-content */}
                </div>
                {/* .nk-block-between */}
              </div>
              {/* .nk-block */}
              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-sm-6 col-lg-4 col-xxl-3">
                    <div className="card h-100">
                      <div className="card-inner my-auto">
                        <div className="team">
                          <div className="user-card user-card-s2">
                            <div className="user-avatar md user-profile-logo">
                              <span>
                                <img
                                  className="w-100"
                                  src="/assets/images/avatar/avatar.png"
                                  alt="avatar"
                                />
                              </span>
                              <div className="status dot dot-lg dot-success" />
                            </div>
                            <div className="user-info">
                              <h6>{ data ? `${data?.user?.fullname}` : ''}</h6>
                              <span className="sub-text text-black fw-normal">
                                {data?.user?.username}
                              </span>
                              {/* <span className="sub-text text-black fw-normal">Password: Madam12#_</span> */}
                              {/* <span className="sub-text text-black fw-normal">{data?.permission}</span> */}
                            </div>
                          </div>
                        </div>
                        {/* .team */}
                      </div>
                      {/* .card-inner */}
                    </div>
                    {/* .card */}
                  </div>
                  <div className="col-sm-6 col-lg-8 col-xxl-9">
                    <div className="card h-100">
                      <div className="card-inner">
                        <div className="user-details-container">
                          <div className="user-details">
                            <p className="mb-0">Phone:</p>
                            <p>{data?.shipping_info?.phone_no}</p>
                          </div>
                          <div className="user-details">
                            <p className="mb-0">Address:</p>
                            <p>{data?.shipping_info?.address}</p>
                          </div>
                          {/* <div className="user-details">
								<p className="mb-0">Zip Code:</p>
								<p>3300056</p>
							</div> */}
                          {/* <div className="user-details">
                            <p className="mb-0">Country:</p>
                            <p>{data?.shipping_info?.country}</p>
                          </div> */}
                          <div className="user-details">
                            <p className="mb-0">City:</p>
                            <p>{data?.shipping_info?.city}</p>
                          </div>
                          <div className="user-details">
                            <p className="mb-0">State/Province:</p>
                            <p>{data?.shipping_info?.state}</p>
                          </div>
                          <div className="user-details">
                            <p className="mb-0">Total Amount:</p>
                            <p>{data?.total_amount}</p>
                          </div>
                        </div>
                        {/* .team */}
                      </div>
                      {/* .card-inner */}
                    </div>
                    {/* .card */}
                  </div>

                  {/* .col */}
                </div>
                {/* .row */}
              </div>
              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-md-12">
                    <div className="card h-100">
                      <div className="nk-ecwg nk-ecwg2">
                        <div className="card-inner pe-0 ps-0">
                          <div className="card-title-group mt-n1 mb-2 pe-3 pe-sm-4 ps-3 ps-sm-4">
                            <div className="card-title">
                              <h6 className="title card-title text-black">
                                Order List
                              </h6>
                            </div>
                            {/* <p className="mb-0 text-primary">View All </p> */}
                            
                          </div>
                          <OrderList id={data._id} data={data.items} loading={loading} />
                        </div>
                        {/* .card-inner */}
                      </div>
                      {/* .nk-ecwg */}
                    </div>
                    {/* .card */}
                  </div>
                  {/* .col */}
                </div>
              </div>
              {/* .nk-block */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
