import React from 'react'
import Skeleton from "../skeletons/skeleton";
import users from "../../assets/images/users.png"


export default function DashboardAnalysis({value, title, accept, increase, decline, loading}) {

	const formatNumber = (value) => {
		return new Intl.NumberFormat('en-NG', {
		  minimumFractionDigits: 2,
		  maximumFractionDigits: 2
		}).format(isNaN(Number(value)) ? 0 : Number(value));
	  };


  return (
    <>
      
      <div className="col-md-4">
        <div style={{borderRadius: '15px'}} className="card card-full">
          <div className="card-inner">
            <div className="card-title-group align-start mb-0">
              <div className="card-title d-flex align-items-center">
				<img src={users} alt='users' className='me-2' />
                <h6 className="subtitle fs-6 tb-ff">
                  {title}
                </h6>
              </div>
              {/* <div className="card-tools">
                <em
                  className="card-hint icon ni ni-help-fill"
                  data-toggle="tooltip"
                  data-placement="left"
                  title
                  data-original-title="Total Deposited"
                />
              </div> */}
            </div>
            <div className="card-amount" id="totalapprovedcrypto">
              {" "}
              <span className={`amount title text-black fw-medium mt-3 w-100 ${loading && 'mb-2'}`}>{loading ? <Skeleton type='text' /> : `${!(title?.toLowerCase() === "total users") ? '₦' : ''}  ${formatNumber(value)}`}</span>
            </div>
            {!(title?.toLowerCase() === "total users") && <div className="invest-data">
              <div className="invest-data-amount g-2">
                <div className="invest-data-history">
                  <div className="amount" id="totalpendingcrypto">
                    <span style={{fontSize: '12px'}} className={`subtitle mt-3 w-100 ${formatNumber(increase) < 0 ? "text-danger" : "text-success"}`}>{loading ? <Skeleton type='text' /> : `${formatNumber(increase)}%`}</span>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
        {/* .card */}
      </div>
    </>
  );
}
