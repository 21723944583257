import { Link } from "react-router-dom";
import _route from "../../constants/routes";
import './admin.css'
import AgentTableList from "../../component/agent/agenttablelist";
import { useState } from "react";


export default function Agent() {
	const [list, setList] = useState(0)
	const [mobileAdd, setMobileAdd] = useState(false)

return (
	<>
		<div className="nk-content ">
		<div className="container-fluid">
			<div className="nk-content-inner">
			<div className="nk-content-body">
				<div className="nk-block-head nk-block-head-sm mt-4">
				<div className="nk-block-between">
					<div className="nk-block-head-content">
					<h4 className="page-title cus-page-title">Agent Overview</h4>
					<div className="nk-block-des">
						<p>
                        You have a total of <strong>{list}</strong> Agents.</p>
					</div>
					</div>
					
				  
					<div className="nk-block-head-content">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
						onClick={() => setMobileAdd(prv => !prv)}
                        className={`btn btn-icon btn-trigger toggle-expand bg-paybond mr-n1 ${mobileAdd ? 'active' : ''}`}
                        data-target="pageMenu"
                      >
                        <em className="icon ni text-white ni-more-v" />
                      </a>
                      <div
                        className="toggle-expand-content"
                        style={{display: ` ${mobileAdd ? 'block' : 'none'}`}}
                      >
                        <ul className="nk-block-tools g-3">
                          <li>
					<Link to={_route._admin_add_agent} className="btn btn-round btn-lg btn-primary bg-paybond tb-ff"><em className="icon ni ni-plus" /><span>Add Agent</span></Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

					{/* .nk-block-head-content */}
				</div>
				{/* .nk-block-between */}
				</div>
				{/* .nk-block */}
				<div className="nk-block">
				<div className="row g-gs">
					<div className="col-md-12">
					<div className="card h-100">
						<div className="nk-ecwg nk-ecwg2">
						<div className="card-inner pe-0 ps-0">
							<div className="card-title-group mt-n1 mb-2 pe-3 pe-sm-4 ps-3 ps-sm-4">
							<div className="card-title">
								<h6 className="title card-title text-black">Agent</h6>
							</div>
							{/* <p className="mb-0 text-primary">View All </p> */}
							</div>
							<AgentTableList setList={setList} />
						</div>
						{/* .card-inner */}
						</div>
						{/* .nk-ecwg */}
					</div>
					{/* .card */}
					</div>
					{/* .col */}
				</div>
				{/* .row */}
				</div>
				{/* .nk-block */}
			</div>
			</div>
		</div>
		</div>

	</>

  );
}
