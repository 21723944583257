import { useEffect, useState, useRef } from "react";
import AuthService from "../../services/user/auth";
import { useDispatch } from 'react-redux'
import { setUser } from "../../redux/authreducer"
import { useSelector } from "react-redux";
import axios from "axios";

const useVerifyUSer = () => {
    const [loading, setloading] = useState(false);
	const user = useSelector((state) => state.auth.user)
    const dispatch = useDispatch()
    const CancelToken = axios.CancelToken;
    const source = useRef();

   
    const verifyOtp = async (data) => {
        if (source.current === undefined) {
            source.current = CancelToken.source();
          }
        try {
			const otpFormData = {
				email: user.username ,
				otp: data.otp,
				user: user
				}
            setloading(true);
            const res = await AuthService.verifyOtp(otpFormData, source.current );

            if(!res) {
				window.NioApp.Toast('An error occured', "warning");
            }else{
                setloading(false);
                if(res.status === 200 || res.status === 201){
					console.log(res.data.result)
					const newUser = {
						...user,
						token: res.data.result.token,
						refresToken: res.data.result.refresToken
					}
					console.log("new user", newUser);
                    await dispatch(setUser(newUser))
					window.NioApp.Toast(res.data.message, "success");
                    localStorage.setItem("user", JSON.stringify(newUser));
                    return true
                }
            }
            
        } catch (error) {
            setloading(false);
            if (axios.isCancel(error)) {
                console.log(error);
            } else {
                if(error.response){
                    console.log(error)
					window.NioApp.Toast(error?.response?.data?.message, "warning");
                }else{
                    console.log(error)
					window.NioApp.Toast(error?.message, "warning");
                }
            }
        }
       
    }
    
    useEffect(()=>{
        return () =>{ 
            if (source.current != null) source.current.cancel()
        }
    }, [])

    return {verifyOtp, loading};
}
 
export default useVerifyUSer;