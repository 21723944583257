import InputField from "../common/input"
import { useEffect, useState } from "react";
import Spinnar from "../../helpers/spinnar";
import empty from "../../assets/images/oops.png";
import edit from "../../assets/images/edit.svg"
import useGetAllDeliveryLocation from "../../hooks/settings/usegetdeliverylocation";
import useAddDeliveryLocation from "../../hooks/settings/useadddeliverylocation";
import useUpdateDeliveryLocation from "../../hooks/settings/useupdatedeliverylocation";
import _route from "../../constants/routes";
import ModalWithHeader from '../modalwithtitle'
import state from "../../helpers/states.json"

export default function Profile() {
	const {getLocation, data, loading} = useGetAllDeliveryLocation();
	const {addLocation, data: addData, loading: addLoading} = useAddDeliveryLocation();
	const {updateLocation, data: upDateData, loading: upDateLoading} = useUpdateDeliveryLocation();
	const [mobileAdd, setMobileAdd] = useState(false)
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [editState, setEditState] = useState({
		_id: "",
		state: "",
		price: "",
	})
	const [formData, setFormData] = useState({
	  state: "",
	  price: "",
	});

	const handleClose = ()=>{

		setEditState(null)
		setFormData({
			state: "",
			price: "",
		  })
		setAddModal(false)
		setEditModal(false)
	}

	
	const handlenameChange = (e) => {
		const { name, value } = e.target;
		console.log(name, value)
		// const rawValue = value.replace(/[^\d]/g, ''); // Remove non-numeric characters
		console.log(value);
		setFormData((prv) => ({ ...prv, [name]: value }));
	};
	const handleEditnameChange = (e) => {
		const { name, value } = e.target;
		console.log(name, value)
		// const rawValue = value.replace(/[^\d]/g, ''); // Remove non-numeric characters
		console.log(value);
		setEditState((prv) => ({ ...prv, [name]: value }));
	};



	const handleSubmitForm = async(e)=>{
		e.preventDefault()
		await addLocation(formData)
	  }
	  const handleUpdateSubmitForm = async(e)=>{
		e.preventDefault()
		const update = {
			state: editState?.state,
			price: `${parseFloat(editState?.price)}`
		}
		const id = editState?._id
		await updateLocation(id, update)
	  }


	  useEffect(() => {
		getLocation()
	  }, [addData, upDateData])

	  useEffect(()=>{
		if(addData || upDateData){
			handleClose()
		}
	  }, [addData, upDateData])

  return (
	<>
		<div id="delivery-location" className="tab-container">
			
			
			<div className="nk-block-head nk-block-head-sm mt-4">
				<div className="nk-block-between">
					<div className="nk-block-head-content">
					<h6 className="">Set Delivery Fee</h6>
					<p className="setting-sub-title">
					To change your password please type in your current password.
					</p>
					</div>
					
				  
					<div className="nk-block-head-content">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
						onClick={() => setMobileAdd(prv => !prv)}
                        className={`btn btn-icon btn-trigger toggle-expand bg-paybond mr-n1 ${mobileAdd ? 'active' : ''}`}
                        data-target="pageMenu"
                      >
                        <em className="icon ni text-white ni-more-v" />
                      </a>
                      <div
                        className="toggle-expand-content"
                        style={{display: ` ${mobileAdd ? 'block' : 'none'}`}}
                      >
                        <ul className="nk-block-tools g-3">
                          <li>
					<div onClick={() => setAddModal(true)} className="btn cursor-pointer btn-round btn-lg btn-primary bg-paybond tb-ff"><em className="icon ni ni-plus" /><span>Create Fee</span></div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

					{/* .nk-block-head-content */}
				</div>
				{/* .nk-block-between */}
				</div>

			{loading ? <div className="text-center mt-5"> <Spinnar/></div> :
				data?.length > 0 ?
				<table className="table table-bordered">			
					<thead>
						<tr>
						<th scope="col">#</th>
						<th scope="col">State</th>
						<th scope="col">Price</th>
						</tr>
					</thead>
					<tbody>
						{data?.map((state, index)=>{
							return (
								<tr className="state-row" key={state._id}>									
									<th scope="row">{index + 1}</th>
									<td>{state.state}</td>
									<td className="state-price">
										{state.price} 
										<span 
											className="edit-btn bg-paybond" 
											onClick={()=> {
												setEditState({
													_id: state?._id,
													state: state?.state,
													price: state?.price,
												}); 
												setEditModal(true);
											}}
										>
											<img src={edit} alt="edit" />
										</span> 
									</td>
								</tr>
							)
						})}
						<tr>
						</tr>
					</tbody>
				</table>
				:
				<div className="text-center mt-5">
					<img src={empty} alt="empty" className="w-10 h-10"/>
					<div>No data found</div>
				</div>
			}

			
		</div>

		
		{addModal && (
				<ModalWithHeader
				  handleClose={handleClose}
				  showModal={addModal}
				  header='Add Location Price'
				>
				  
				  
							<form
							onSubmit={handleSubmitForm}
							className="auth-form px-3 py-4 rounded-4"
							>
							{/* .form-group */}
							<div className="form-group">								
								<label className='auth-label'>Select State</label>
								<select 
									value={formData.state} 
									onChange={(e)=> setFormData(prv => ({...prv, state:e.target.value}))} 
									className={`form-control form-control-lg auth-field`}
								>
									<option selected hidden></option>
									{
										state?.map((state, index)=>{
											return (
												<option key={index}>{state}</option>
											)
										})
									}
								</select>
							</div>
							<div className="form-group">
								<InputField 
								label="Select Price"
								name="price"
								type="number"
								placeholder=""
								value={formData.price}
								change={handlenameChange}
								/>
							</div>
							
							<div className="mt-4 btn-container">
								<button className="delete-acct-btn w-100 bg-paybond text-white active-btn">
								{addLoading ? <Spinnar /> : "Continue"}
								</button>
							</div>
							</form>
					<div className="modal-footer bg-light">
					  <span className="sub-text">
						{/* This adds a cryptocurrency to the listings on the website */}
					  </span>
					</div>
				</ModalWithHeader>
			  )}
		
			  {editModal && (
				<ModalWithHeader
				  handleClose={handleClose}
				  showModal={editModal}
				  header='Edit Location Price'
				>
				  
				  
							<form
							onSubmit={handleUpdateSubmitForm}
							className="auth-form px-3 py-4 rounded-4"
							>
							{/* .form-group */}
							<div className="form-group">								
								<label className='auth-label'>Select State</label>
								<select 
									value={editState.state} 
									onChange={(e)=> setEditState(prv => ({...prv, state:e.target.value}))} 
									className={`form-control form-control-lg auth-field`}
									disabled
								>
									<option selected hidden></option>
									{
										state?.map((state, index)=>{
											return (
												<option key={index}>{state}</option>
											)
										})
									}
								</select>
							</div>
							<div className="form-group">
								<InputField 
								label="Select Price"
								name="price"
								type="number"
								placeholder=""
								value={editState.price}
								change={handleEditnameChange}
								/>
							</div>
							
							<div className="mt-4 btn-container">
								<button className="delete-acct-btn w-100 bg-paybond text-white active-btn">
								{upDateLoading ? <Spinnar /> : "Update"}
								</button>
							</div>
							</form>
					<div className="modal-footer bg-light">
					  <span className="sub-text">
						{/* This adds a cryptocurrency to the listings on the website */}
					  </span>
					</div>
				</ModalWithHeader>
			  )}
		
		
		
			  
			{/* {editModal && (
				<ModalWithHeader
				  handleClose={handleClose}
				  showModal={editModal}
				  header='Edit Crypto Currency'
				>
				  
				  <EditCryptoForm editCrypto={editCrypto} setEditCrypto={setEditCrypto}  handleUpdateCrypto={handleUpdateCrypto} loading={updateLoading} />
					<div className="modal-footer bg-light">
					  <span className="sub-text">
						This adds a cryptocurrency to the listings on the website
					  </span>
					</div>
				</ModalWithHeader>
			  )} */}
	</>
  )
}
