import { useState } from "react";
import Spinnar from "../spinnar";

export default function AddProduct({ handlePostCrypto, loading }) {
  const [crypto, setCrypto] = useState({
    title: "",
    description: "",
    logo: "",
	price: '',
	sub_title: '',
	quantity: ''
  });

  // Function to handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    const reader = new FileReader();

    reader.onload = (event) => {
      // When file is read, update the state with the file contents
      setCrypto((prevState) => ({
        ...prevState,
        logo: file, // Use event.target.result to get the file contents as a data URL
      }));
      const logoLabel = document.getElementById("logoLabel");
      console.log(logoLabel);
      if (logoLabel) {
        console.log("label");
        logoLabel.innerText = file.name;
      }
    };

    console.log(crypto);
    reader.readAsDataURL(file); // Read the file as a data URL
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(crypto);
    const formData = new FormData();

    formData.append("title", crypto.title);
    formData.append("description", crypto.description);
    formData.append("price", crypto.price);
    formData.append("quantity", crypto.quantity);
    formData.append("sub_title", crypto.sub_title);
    formData.append("image", crypto.logo);

    for (let entry of formData.entries()) {
      // entry is an array [key, value]
      const [key, value] = entry;

      // Log the key and value to see details
      console.log(`Key: ${key}, Value: ${value}`);
    }
    handlePostCrypto(formData);
  };

  // Function to handle change in selection
//   const handleChange = (event) => {
//     const value = event.target.value;
// 	if(crypto.size.includes(value)){
// 		setCrypto((prevState) => ({
// 		  ...prevState,
// 		  size: crypto.size.filter(size => size !== value), 
// 		}));
// 	}else{
// 		setCrypto((prevState) => ({
// 		  ...prevState,
// 		  size: [...crypto.size, value], 
// 		}));
// 	}
//   };

  return (
    <>
      <div className="modal-body">
        <form className="form-validate is-alter" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              Title
            </label>
            <div className="form-control-wrap">
              <input
                required
                type="text"
                value={crypto.title}
                onChange={(e) =>
                  setCrypto((prv) => ({ ...prv, title: e.target.value }))
                }
                className="form-control"
                id="full-name"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="sub_title">
              Subtitle
            </label>
            <div className="form-control-wrap">
              <input
                required
                type="text"
                value={crypto.sub_title}
                onChange={(e) =>
                  setCrypto((prv) => ({ ...prv, sub_title: e.target.value }))
                }
                className="form-control"
                id="full-name"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="email-address">
              Description
            </label>
            <div className="form-control-wrap">
              <textarea
                required
				rows={5}
                value={crypto.description}
                onChange={(e) =>
                  setCrypto((prv) => ({ ...prv, description: e.target.value }))
                }
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              Price
            </label>
            <div className="form-control-wrap">
              <input
                required
                type="number"
                value={crypto.price}
                onChange={(e) =>
                  setCrypto((prv) => ({ ...prv, price: e.target.value }))
                }
                className="form-control"
                id="full-name"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              Quantity
            </label>
            <div className="form-control-wrap">
              <input
                required
                type="number"
                value={crypto.quantity}
                onChange={(e) =>
                  setCrypto((prv) => ({ ...prv, quantity: e.target.value }))
                }
                className="form-control"
                id="full-name"
              />
            </div>
          </div>
          {/* <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              Size
            </label>
            <div className="form-control-wrap">
				
              <select
                required
                type="text"
                value={crypto.size}
                onChange={(e) => handleChange(e)}
                className="form-control"
				multiple 
                id="full-name"
				>
					<option hidden value=''></option>
					<option style={{ backgroundsub_title: crypto.size.includes('S') ? '#0057FD' : 'transparent', sub_title: crypto.size.includes('S') ? 'white' : 'initial' }} value='S'>S</option>
					<option style={{ backgroundsub_title: crypto.size.includes('M') ? '#0057FD' : 'transparent', sub_title: crypto.size.includes('M') ? 'white' : 'initial' }}  value='M'>M</option>
					<option style={{ backgroundsub_title: crypto.size.includes('L') ? '#0057FD' : 'transparent', sub_title: crypto.size.includes('L') ? 'white' : 'initial' }}  value='L'>L</option>
					<option style={{ backgroundsub_title: crypto.size.includes('XL') ? '#0057FD' : 'transparent', sub_title: crypto.size.includes('XL') ? 'white' : 'initial' }}  value='XL'>XL</option>
				</select>
            </div>
          </div> */}
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              Product Image
            </label>
            <div className="form-control-wrap">
              <div className="custom-">
                <input
                  required
                  type="file"
                  name="logo"
                  onChange={(e) => handleFileChange(e)}
                  // className="custom-file-input"
                  id="customLogoFile"
                />
                <label
                  id="logoLabel"
                  className="custom-file-label"
                  htmlFor="customLogoFile"
                >
                  Choose file
                </label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-lg btn-primary bg-paybond tb-ff">
              {loading ? <Spinnar /> : "Add Product"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
