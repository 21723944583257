import myApi from "../common/interceptors/apiinterceptor";
import {  _getAllUser, _GetUserWallet, _getSingleUser, _UpdateUser, _deleteAgent, _getSingleAgent, _updateAgent, _getAnalysis, _getAllAgent, _updateWallet, _getUserWallet, _postAgent } from "../../network/network"

const Adminservices = {
	getAllUser: async (source) => {
		return await myApi.get(_getAllUser, {
		cancelToken: source.token,
		}).then(async (res) => {
		return res;
		});

	},
	UserBalance: async (id, source) => {
		return await myApi.get(`${_GetUserWallet}`, {
			cancelToken: source.token,
			params: {
				user: id,
			}
		}).then(async (res) => {
			return res;
		});

		},
	getSingleUser: async (id, source) => {
		return await myApi.get(`${_getSingleUser}/${id}`, {
		cancelToken: source.token,
		}).then(async (res) => {
		return res;
		});

	},
	postAgent: async (data, source) => {
		return await myApi.post(_postAgent, data, {
		  cancelToken: source.token,
		}).then(async (res) => {
		  return res;
		});
	
	  },
	  getUserWallet: async (data, source) => {
		return await myApi.post(_getUserWallet, data, {
		  cancelToken: source.token,
		}).then(async (res) => {
		  return res;
		});
	
	  },
	  getAssignPerryPoint: async (data, id, source) => {
		return await myApi.post(`${_updateWallet}/${id}`, data, {
		  cancelToken: source.token,
		}).then(async (res) => {
		  return res;
		});
	
	  },
	  getAllAgent: async (source) => {
		return await myApi.get(_getAllAgent, {
		  cancelToken: source.token,
		}).then(async (res) => {
		  return res;
		});
	
	  },
	  getAnalysis: async (source) => {
		return await myApi.get(_getAnalysis, {
		  cancelToken: source.token,
		}).then(async (res) => {
		  return res;
		});
	
	  },
	  UpdateUser: async (data, id, source) => {
		return await myApi.patch(`${_updateAgent}/${id}`, data,  {
		  cancelToken: source.token,
		}).then(async (res) => {
		  return res;
		});
	
	  },
	  getSingleAgent: async (id, source) => {
		  return await myApi.get(`${_getSingleAgent}/${id}`, {
		  cancelToken: source.token,
		  }).then(async (res) => {
		  return res;
		  });
  
	  },
	  deleteAgent: async (id, source) => {
		  return await myApi.delete(`${_deleteAgent}/${id}`, {
		  cancelToken: source.token,
		  }).then(async (res) => {
		  return res;
		  });
  
	  },
	//   getAllUser: async (source) => {
	// 	  return await myApi.get(_getAllUser, {
	// 	  cancelToken: source.token,
	// 	  }).then(async (res) => {
	// 	  return res;
	// 	  });
  
	//   },
	  UpdateAppUser: async (data, id, source) => {
		  return await myApi.patch(`${_UpdateUser}/${id}`, data,  {
			  cancelToken: source.token,
		  }).then(async (res) => {
			  return res;
		  });
  
		  },
	//   getSingleUser: async (id, source) => {
	// 	  return await myApi.get(`${_getSingleUser}/${id}`, {
	// 	  cancelToken: source.token,
	// 	  }).then(async (res) => {
	// 	  return res;
	// 	  });
  
	//   },


}

export default Adminservices