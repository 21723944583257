import {useEffect} from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import "./myfastii.css"
import EnvelopeIcon from '../../assets/images/envelope.svg'; // Adjust path as needed
import Skeleton from '../skeletons/skeleton';
import _route from '../../constants/routes';
import useGetAllNotification from '../../hooks/notification/usegetnotification';

const NotificationsList = ({  notificationalert, for_who, dashboard }) => {
	const {getAllNotification, data: notifications, loading} = useGetAllNotification();

		const handleGetBanner = async () => {
			await getAllNotification("staffs");
		};
	
	
		useEffect(() => {
			handleGetBanner()
		}, []);
  return (
    <div className="tranx-list rounded-5 tranx-list-stretch card"><div className="card-title">
	{dashboard ?
	<h6 className="title card-title text-black ps-3 pt-4 ">
		Quick Notification
	</h6>
	:
	<h6 className="title fw-semibold fs-4 ps-3 pt-4 card-title text-black">
		Notifications
	</h6>}
  </div>

      {
		loading ?
		[1,2,3,4,5,6,7,8,9,0].map((notification) => (
			<div key={notification._id} className="tranx-item">
			  
			  <div className="tranx-col" style={{ display: 'flex', flexDirection: 'row', width: "80%" }}>
				{/* <div className="svgicon-wrap little-wrap bg-transparent ">
					<Skeleton type="avatar" />
				</div> */}
				<div 
				  className={`unread-${notification.seen}`} 
				  style={{ position: 'absolute' }}
				></div>
				<div className="tranx-info w-available">
				  <div className="tranx-data">
				  <div className="tranx-date truncate mb-1">
					  <Skeleton type="text" />
					</div>
					<div className="tranx-label w-available mb-1">
					  {/* <Link to={`/view-withdrawal-single/${notification._id}`}>
						{notification.title.substring(0, 35)}...
					  </Link> */}
					  <Skeleton type="title"  />
					</div>
					<div className="tranx-date truncate">
					  <Skeleton type="text" />
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  ))	
		:
		notifications?.filter(item => item?.for_who?.toLowerCase() === 'staffs').length > 0 
		? 
		(
			dashboard ?
			notifications?.filter(item => item?.for_who?.toLowerCase() === 'staffs').slice(0,5).map((notification, index) => (
				<Link to={`${notificationalert ? _route._admin_single_notif : _route._admin_single_super_notif}/${notification?._id}${for_who ? `?for_who=${for_who}` : ""}`} key={index} className="tranx-item">
					{/* <Link 
					//   to={`/single-notification/${index}`}
					to="#" 
					className="wide-link"
					></Link> */}
					
					<div className="tranx-col" style={{ display: 'flex', flexDirection: 'row' }}>
					
					<div 
						className={`unread-${true}`} 
						style={{ position: 'absolute' }}
					></div>
					<div className="tranx-info">
						<div className="tranx-data">
							
						<div className="tranx-amount text-start">
						<div className="number-sm">
						<strong>
							{moment(notification.createdAt).fromNow()}
							<span className="currency currency-btc"></span>
						</strong>
						</div>
					</div>
						<div className="tranx-label">
							{/* <Link to={`/view-withdrawal-single/${index}`}> */}
							{notification.title.substring(0, 35)}...
							{/* </Link> */}
						</div>
						<div className="tranx-date truncate">
							{notification.message.substring(0, 55)}...
						</div>
						</div>
					</div>
					</div>
				</Link>
			)) 
			:
			notifications?.filter(item => item?.for_who?.toLowerCase() === 'staffs').map((notification, index) => (
				<Link to={`${notificationalert ? _route._admin_single_notif : _route._admin_single_super_notif}/${notification?._id}${for_who ? `?for_who=${for_who}` : ""}`} key={index} className="tranx-item">
					{/* <Link 
					//   to={`/single-notification/${index}`}
					to="#" 
					className="wide-link"
					></Link> */}
					
					<div className="tranx-col" style={{ display: 'flex', flexDirection: 'row' }}>
					
					<div 
						className={`unread-${true}`} 
						style={{ position: 'absolute' }}
					></div>
					<div className="tranx-info">
						<div className="tranx-data">
							
						<div className="tranx-amount text-start">
						<div className="number-sm">
						<strong>
							{moment(notification.createdAt).fromNow()}
							<span className="currency currency-btc"></span>
						</strong>
						</div>
					</div>
						<div className="tranx-label">
							{/* <Link to={`/view-withdrawal-single/${index}`}> */}
							{notification.title.substring(0, 35)}...
							{/* </Link> */}
						</div>
						<div className="tranx-date truncate">
							{notification.message.substring(0, 55)}...
						</div>
						</div>
					</div>
					</div>
				</Link>
			))
		) 
	  : (
        <div className="text-center p-4">
          <p>No notifications found.</p>
        </div>
      )
	  
	  }
    </div>
  );
};

export default NotificationsList;