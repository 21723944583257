import InputField from "../common/input"
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import useUpdateUser from "../../hooks/user/useupdateuser";
import Spinnar from "../../helpers/spinnar";

export default function Profile() {
	const user = useSelector((state) => state.auth.user)
	const {UpdateUser, data, loading} = useUpdateUser()
	const [formData, setFormData] = useState({
		transactionFee: "",
		feeCap: "",
	});

	
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		console.log(name, value)
		const rawValue = value.replace(/[^\d]/g, ''); // Remove non-numeric characters
		console.log(rawValue);
		setFormData((prv) => ({ ...prv, [name]: rawValue }));
	};	
	const handlenameChange = (e) => {
		const { name, value } = e.target;
		console.log(name, value)
		// const rawValue = value.replace(/[^\d]/g, ''); // Remove non-numeric characters
		console.log(value);
		setFormData((prv) => ({ ...prv, [name]: value }));
	};



	  const handleSubmitForm = async(e)=>{
		e.preventDefault()
		const upDatedData = {
			fullname: formData.fullname,
			phone_no: formData.phone_no
		}
		// await UpdateUser(upDatedData)
	  }


  return (
	<>
		<div id="transaction-fee" className="tab-container">
			<h6 className="mt-4">Set transaction fee</h6>
			
			<form
			onSubmit={handleSubmitForm}
			className="auth-form px-3 py-4 rounded-4"
			>
			{/* .form-group */}
			<div className="form-group">
				<InputField 
				label="Transaction Fee"
				name="transactionFee"
				type="text"
				placeholder=""
				value={formData.transactionFee}
				change={handlenameChange}
				/>
			</div>
			<div className="form-group">
				<InputField 
				label="Fee Cap"
				name="feeCap"
				type="text"
				placeholder=""
				value={formData.feeCap}
				change={handleInputChange}
				readOnly
				/>
			</div>
			<div className="mt-4 btn-container">
				<button className="delete-acct-btn w-100 bg-paybond text-white active-btn">
				{loading ? <Spinnar /> : "Save"}
				</button>
			</div>
			</form>
		</div>
	</>
  )
}
