import { useParams } from "react-router-dom";
import InputField from "../../component/common/input"
import { useEffect, useState } from "react";
import useGetSingleUser from "../../hooks/user/usegetsingleuser";
import useGetUserWalletBallance from "../../hooks/user/usegetuserwallet";
import useUpdateUserWalletBallance from "../../hooks/user/useupdateuserwallet";
import PaybillsTransactionList from "../../component/transaction/unlimitedpaybillstransactionlist";
import CardTransactionList from "../../component/transaction/unlimitedgiftcardtransactionlist";
import Skeleton from "../../component/skeletons/skeleton";
import Spinnar from "../../helpers/spinnar";
import TwoCharacterComponent from "../../helpers/twocharactercomponent";
import ModalWithHeader from '../../component/modalwithtitle'


export default function OrdersOverView() {
	const {getSingleUser, data, loading} = useGetSingleUser()
	const {GetUserWalletBallance, data: balanceData, loading: balanceLoading} = useGetUserWalletBallance()
	const {UpdateUserWalletBallance, data: upDateData, loading: upDateLoading} = useUpdateUserWalletBallance()
	const [index, setIndex] = useState(0)
	const [addModal, setAddModal] = useState(false)
	const [formData, setFormData] = useState({
		amount: "",
		type: ""
	})
	const {id} = useParams()

	const handleGetUser = async () =>{
		await getSingleUser(id)
	}
	const handleGetUserBalance = async () =>{
		await GetUserWalletBallance(id)
	}

	useEffect(()=>{
		handleGetUser()
	},[])

	
	const handlenameChange = (e) => {
		const { name, value } = e.target;
		console.log(name, value)
		// const rawValue = value.replace(/[^\d]/g, ''); // Remove non-numeric characters
		console.log(value);
		setFormData((prv) => ({ ...prv, [name]: value }));
	};


	useEffect(()=>{
		handleGetUserBalance()
	},[ upDateData])


	  const handleSubmitForm = async(e)=>{
		e.preventDefault()
		await UpdateUserWalletBallance(id, formData) && handleClose()
	  }
	  

	const handleClose = ()=>{
		setFormData({
			amount: "",
			type: ""
		  })
		setAddModal(false)
	}


	return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              
              {/* .nk-block */}
              <div className="nk-block">
                <div className="row g-gs">
                  <div className="">
                    <div className="card h-100">
                      <div className="card-inner d-flex flex-column flex-md-row justify-between items-center my-auto">
                        <div className="team col-md-4">
                          <div className="user-card flex-row justify-center justify-content-md-start items-center h-100">
                            <div className="user-avatar ">
                              <span>
                                {/* <img
                                  className="w-100"
                                  src={userPic}
                                  alt="avatar"
                                /> */}
								
								{loading ? 
							<div
							className="user-avatar">
								<Skeleton type='avatar'/>
							</div> 
							: 
							<span><TwoCharacterComponent data={data?.fullname} /> </span>}
                              </span>
                              {/* <div className="status dot dot-lg dot-success" /> */}
                            </div>
                            <div className="user-info mt-0 w-fit">
                              {loading ? <div className="w-100 mb-1"><Skeleton type="text" /> </div>  : <h6>{data?.fullname}</h6>}
                              {loading ? <div className="w-100"><Skeleton type="text" /> </div>  : <span className="sub-text fw-normal">
								{data?.username}
                              </span>}
                              {/* <span className="sub-text text-black fw-normal">Password: Madam12#_</span> */}
                              {/* <span className="sub-text text-black fw-normal">{data?.permission}</span> */}
                            </div>
                          </div>
                        </div>
                        <div className="team d-flex justify-center items-center my-3 my-md-0 col-md-4">
                          <div className="user-card items-center justify-center user-card-s2">
                            <div className="success-btn">
                              <span style={{color: '#202224'}} className="fw-normal">Wallet Balance</span>
							  
							  <p className=" fw-semibold">
							  N{balanceLoading ?
							   <Spinnar />
							   :balanceData?.balance?.toLocaleString()
							  }
                              </p>
							
                              {/* <span className="sub-text text-black fw-normal">Password: Madam12#_</span> */}
                              {/* <span className="sub-text text-black fw-normal">{data?.permission}</span> */}
                            </div>
                          </div>
                        </div>
                        <div className="team col-md-4">
                          <div className="user-card flex-row flex-wrap paybond-gap-5 justify-center items-center user-card-s2">
                           
							<p onClick={() => setAddModal(true)} className="primary-btn mb-0 cursor-pointer fw-normal">
                                 Edit Balance
                              </p>
                              <p className="primary-btn cursor-pointer fw-normal">
                                Lock Account
                              </p>
                          </div>
                        </div>
                        {/* .team */}
                      </div>
                      {/* .card-inner */}
                    </div>
                    {/* .card */}
                  </div>

                  {/* .col */}
                </div>
                {/* .row */}
              </div>
              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-md-12">
                    <div className="card h-100">
                      <div className="nk-ecwg nk-ecwg2">
                        <div className="card-inner pe-0 ps-0">
                          <div className="card-title-group mt-n1 mb-2 pe-3 pe-sm-4 ps-3 ps-sm-4">
                            <div className="card-title">
                              <h6 className="title card-title text-black">
                                {index === 0 && 'Paybills Transaction'}
                                {index === 1 && 'Groceries Transaction'}
                              </h6>
                            </div>
                            {/* <p className="mb-0 text-primary">View All </p> */}
                            <div className="card-tools">
                              <span
								onClick={() => setIndex(0)}
                                className={`xo-toggle left ${index === 0 ?  'bg-paybond text-white' : "text-paybond"}`}
                                style={{ cursor: "pointer" }}
                              >
                                Paybills
                              </span>
                              <span
								onClick={() => setIndex(1)}
                                className={`xo-toggle right ${index === 1 ?  'bg-paybond text-white' : "text-paybond"}`}
                                style={{ cursor: "pointer" }}
                              >
                                Groceries
                              </span>
                            </div>
                          </div>
                          {index === 0 && <PaybillsTransactionList userId={id} />}
                          {index === 1 && <CardTransactionList userId={id} />}
                        </div>
                        {/* .card-inner */}
                      </div>
                      {/* .nk-ecwg */}
                    </div>
                    {/* .card */}
                  </div>
                  {/* .col */}
                </div>
              </div>
              {/* .nk-block */}
            </div>
          </div>
        </div>
      </div>

	  
		
		{addModal && (
				<ModalWithHeader
				  handleClose={handleClose}
				  showModal={addModal}
				  header='Update Wallet Balance'
				>
				  
				  
							<form
							onSubmit={handleSubmitForm}
							className="auth-form px-3 py-4 rounded-4"
							>
							{/* .form-group */}
							<div className="form-group">								
								<label className='auth-label'>Select Type</label>
								<select 
									value={formData.type} 
									onChange={(e)=> setFormData(prv => ({...prv, type:e.target.value}))} 
									className={`form-control form-control-lg auth-field`}
								>
									<option selected hidden></option>									
									<option value='Credit'>Credit</option>									
									<option value='Debit'>Debit</option>
								</select>
							</div>
							<div className="form-group">
								<InputField 
								label="Amount"
								name="amount"
								type="number"
								placeholder=""
								value={formData.amount}
								change={handlenameChange}
								/>
							</div>
							
							<div className="mt-4 btn-container">
								<button className="delete-acct-btn w-100 bg-paybond text-white active-btn">
								{upDateLoading ? <Spinnar /> : "Continue"}
								</button>
							</div>
							</form>
					<div className="modal-footer bg-light">
					  <span className="sub-text">
						{/* This adds a cryptocurrency to the listings on the website */}
					  </span>
					</div>
				</ModalWithHeader>
			  )}
    </>
  );
}
