import InputField from "../common/input"
import { useState } from "react";
import useChangePassWord from "../../hooks/auth/usechangepassword";
import Spinnar from "../../helpers/spinnar";
import passwordStrength from "../../pages/passwordstrengthvalidation"

export default function Profile() {
	const {changePassWord, data, loading} = useChangePassWord()
		const [upper, setupper] = useState(false)
		const [lower, setlower] = useState(false)
		const [number, setnumber] = useState(false)
		const [character, setcharacter] = useState(false)
		const [minlength, setminlength] = useState(false)
	const [formData, setFormData] = useState({
	  password: "",
	  new_password: "",
	  confirm_password: "",
	});
	
	const handlenameChange = (e) => {
		const { name, value } = e.target;
		console.log(name, value)
		// const rawValue = value.replace(/[^\d]/g, ''); // Remove non-numeric characters
		console.log(value);
		setFormData((prv) => ({ ...prv, [name]: value }));
	};



	  const handleSubmitForm = async(e)=>{
		e.preventDefault()
		
		if((lower && upper && minlength && number && character) === true){
			if(formData.new_password !== formData.confirm_password){
				window.NioApp.Toast("The passwords entered do not match.", "warning");
			}else{
				await changePassWord(formData) && setFormData({
					password: "",
					new_password: "",
					confirm_password: "",
				  })
			}

		}else{
			window.NioApp.Toast("password is too weak", "warning");

		}
	  }

	  	  
	function checkPassword (e){
		passwordStrength.upper.test(e.target.value) ? setupper(true) : setupper(false)
		passwordStrength.lower.test(e.target.value) ? setlower(true) : setlower(false)
		passwordStrength.number.test(e.target.value) ? setnumber(true) : setnumber(false)
		passwordStrength.character.test(e.target.value) ? setcharacter(true) : setcharacter(false)
		passwordStrength.minlength.test(e.target.value) ? setminlength(true) : setminlength(false)
		setFormData(prv => ({...prv, new_password: e.target.value}))
	}

  return (
	<>
		<div id="change-password" className="tab-container">
			<h6 className="mt-4">Change your password</h6>
			<p className="setting-sub-title">
			To change your password please type in your current password.
			</p>
			<form
			onSubmit={handleSubmitForm}
			className="auth-form px-3 py-4 rounded-4"
			>
			{/* .form-group */}
			<div className="form-group">
				<InputField 
				label="Current Password"
				name="password"
				type="text"
				placeholder=""
				value={formData.password}
				change={handlenameChange}
				/>
			</div>
			<div className="form-group">
				<InputField 
				label="New Password"
				name="new_password"
				type="text"
				placeholder=""
				value={formData.new_password}
				change={checkPassword}
				
				/>
			</div>
			{/* .form-group */}
			<div className="form-group">
				<InputField 
				label="Confirm Password"
				name="confirm_password"
				type="text"
				placeholder=""
				value={formData.confirm_password}
				change={handlenameChange}
				/>
			</div>
			<div className="mt-4 btn-container">
				<button className="delete-acct-btn w-100 bg-paybond text-white active-btn">
				{loading ? <Spinnar /> : "Change Password"}
				</button>
			</div>
			</form>
		</div>
	</>
  )
}
