import {
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
	Route,
  } from 'react-router-dom';
import { 
	Dashboard, 
	UserOverView,
	ChatBox as AdminChatBox,
	Product,
	AgentList,
	AgentOverView,
	UserList,
	PaymentInfo,
	Avatar,
	Academy,
	Crypto,
	AddAgent,
	Announcement,
	GiftCardCategory,
	GiftCardCountry,
	GiftCard,
	Orders,
	OrdersOverView,
	ProductBanner,
	UserBanner,
	HomeBanner,
	ProductOverVIew,
	ProductBannerOverVIew,
	AcademyBanner,
	AcademyOverVIew,
	ProfileSetting,
	Transaction,
	Notification,
	SingleNotification,
	SupperSingleNotification,
	Error
	

} from "./pages/admin/index";
import { 
	AgentDashboard,
	ChatBox, 
} from "./pages/agent/index";
import PageLayouts from "./pages/layout/index";
import Login from './pages/login'
import VerifyOtp from './pages/verifylogin';
import _route from './constants/routes';
import PrivateRoute from './routeguard/privateroute'
import './App.css';
import PrivacyPolicy from './pages/privacypolicy';

const router = createBrowserRouter(
	createRoutesFromElements(
	<>
	<Route path={_route._admin_login}  element={<Login />} />
	<Route path={_route._admin_privacy_policy}  element={<PrivacyPolicy />} />
	<Route path={_route._admin_verifyOtp}  element={<VerifyOtp />} />

	{/* Admin Pages  */}
	
	<Route element={<PrivateRoute />} >
		<Route path={_route._admin} element={<PageLayouts.RootLayout />}>
			<Route path={_route._admin_dashboard}  element={<Dashboard />} />
			<Route path={_route._admin_user}  element={<UserList />} />
			<Route path={_route._admin_transaction}  element={<Transaction />} />
			<Route path={_route._admin_user_view}  element={<UserOverView />} />
			<Route path={_route._admin_notification}  element={<Notification />} />
			<Route path={_route._admin_single_super_notification}  element={<SupperSingleNotification />} />
			<Route path={_route._admin_single_notification}  element={<SingleNotification />} />
			<Route path={_route._admin_announcement}  element={<Announcement />} />
			<Route path={_route._admin_user_announcement}  element={<UserBanner />} />
			<Route path={_route._admin_profile_setting}  element={<ProfileSetting />} />
			<Route path={_route._admin_product}  element={<Product />} />
			<Route path={_route._admin_product_overview}  element={<ProductOverVIew />} />
			<Route path={_route._admin_orders_overview}  element={<OrdersOverView />} />
			<Route path={_route._admin_agent}  element={<AgentList />} />
			<Route path={_route._admin_add_agent}  element={<AddAgent />} />
			<Route path={_route._admin_agent_view}  element={<AgentOverView />} />
			<Route path={_route._not_found} element={<Error /> } />


			{/* 
				<Route path={_route._admin_chatbox}  element={<AdminChatBox />} />
				<Route path={_route._admin_paymentinfo}  element={<PaymentInfo />} />
				<Route path={_route._admin_avatar}  element={<Avatar />} />
				<Route path={_route._admin_academy}  element={<Academy />} />
				<Route path={_route._admin_crypto}  element={<Crypto />} />
				<Route path={_route._admin_giftcard}  element={<GiftCardCategory />} />
				<Route path={_route._admin_giftcard_country}  element={<GiftCardCountry />} />
				<Route path={_route._admin_giftcard_country_id}  element={<GiftCard />} />
				<Route path={_route._admin_orders}  element={<Orders />} />
				<Route path={_route._admin_orders_overview}  element={<OrdersOverView />} />
				<Route path={_route._admin_product_banner}  element={<ProductBanner />} />
				<Route path={_route._admin_user_announcement}  element={<UserBanner />} />
				<Route path={_route._admin_home_banner}  element={<HomeBanner />} />
				<Route path={_route._admin_product_banner_overview}  element={<ProductBannerOverVIew />} />
				<Route path={_route._admin_academy_banner}  element={<AcademyBanner />} />
				<Route path={_route._admin_academy_overview}  element={<AcademyOverVIew />} />
				<Route path={_route._admin_notification}  element={<Notification />} />
				<Route path={_route._admin_single_notification}  element={<SingleNotification />} />
			*/}

		</Route>
	</Route>

	</>
	)
  );

function App() {

  return (
    <div className="App">
	<RouterProvider router={router} />
	</div>
  );
}

export default App;
