import { useEffect, useState } from "react";
import Modal from "../../helpers/modal";
import Spinnar from "../../helpers/spinnar";
import { SelectItem } from '../../helpers/selectitem'
import { Link, useNavigate } from "react-router-dom";
import _route from "../../constants/routes";
import emptyState from '../../assets/images/oops.png'
import useGetAllUser from "../../hooks/user/usegetuser";
import Skeleton from "../skeletons/skeleton";
import Profile from "../data/profile";
import TwoCharacterComponent from "../../helpers/twocharactercomponent";

export default function UserTableList({dashboard, setList}) {
	const [showDeleteModal, setDeleteModal] = useState(false)
	const options = { month: "long", year: "numeric", day: "numeric" };
	const [showDeleteAllModal, setDeleteAllModal] = useState(false)
	const {handleSelectAllChecked, handleEachInvoice, selectedItem} = SelectItem()
	const [deleteId, setDeleteId] = useState(null)
	const navigate = useNavigate()
	const {getAllUser, data, loading} = useGetAllUser()
	const deleteLoading = false


	const handleGetAllUser = async ()=>{
		await getAllUser()
	}

	useEffect(()=>{
		handleGetAllUser()
	}, [])

	useEffect(()=>{
		setList && setList(data.length)
	}, [data])
	
	const handleClose =()=>{
		setDeleteModal(false)
		setDeleteAllModal(false)
		setDeleteId(null)
	}

	const handleDelete = async ()=>{
		console.log(deleteId)
		handleClose()
	}

	const handleAllDelete = async ()=>{
		console.log(selectedItem)
		handleClose()
	}
	return ( 
	<>
		<div className="table-container px-2 h-100 mx-1 mx-sm-2">
			<div className="nk-tb-list is-separate tab_contaner mt-n2">
			<div className="nk-tb-item nk-tb-head nk-tb-head-dash">
				{/* <div class="nk-tb-col"><span>Order No.</span></div> */}
				{/* {!dashboard && <div className="nk-tb-col">
					<div className="custom-control custom-control-sm custom-checkbox notext">
					
					</div>
				</div>
				} */}
				<div className="nk-tb-col">
				<span>Name</span>
				</div>
				<div className="nk-tb-col">
				<span>Email</span>
				</div>
				<div className="nk-tb-col tb-col-sm">
				<span>Phone Number</span>
				</div>				
				<div className="nk-tb-col">
				<span>Joined</span>
				</div>
				<div className="nk-tb-col tb-col-md">
				<span>Status</span>
				</div>
			
			</div>

			

			
			{
				loading 
				?
					[1,2,3,4,5].map((element, index)=>{
						return (
							<div key={index} className="nk-tb-item nk-tb-item-dash">
								
								<div className="nk-tb-col">
								<span className="tb-sub table-name">
									
									<div
									className="user-avatar"
									style={{
										background: 'transparent',
										backgroundPosition: "center",
										backgroundSize: "cover",
									}}
									>
										<Skeleton type='avatar' />
									</div>
									<p className="user-name ms-1 w-100 text-truncate">
										<Skeleton type='text' />
									</p>
								</span>
								</div>
								<div className="nk-tb-col">
									<span className="tb-sub text-truncate">
										<Skeleton type='text' />
									</span>
								</div>
								<div className="nk-tb-col tb-col-sm">
									<span className="tb-sub text-truncate">
										<Skeleton type='text' />
									</span>
								</div>
								<div className="nk-tb-col tb-col-md">
								<span className="tb-sub text-primary text-truncate">
									<Skeleton type='text' />
								</span>
								</div>
								<div className="nk-tb-col">
								<span className="tb-lead text-truncate">
									<Skeleton type='text' />
								</span>
								</div>
								<div className="nk-tb-col nk-tb-col-tools">
									<Skeleton type='text' />
								</div>
							</div> 
						)
					})	
				:
				data.length < 1 ?
				
				<div className='child-container'>
					<div className='centered-content'>
						<div className='emptystate-container'>
							{/* <div>
								<div> */}
									<img src={emptyState} alt='no item found' />
									<p>No User</p>
								{/* </div>
							</div> */}
						</div>
					</div>
				</div>
				:
				
				dashboard ?
				data.slice(0, 5).map((user, index) => {
						return (
						<Link to={`${_route._admin_user}/${user?._id}`} key={user?._id} className="nk-tb-item nk-tb-item-dash">
							{/* {!dashboard && <div onClick={(e)=> { e.stopPropagation()}} className="nk-tb-col nk-tb-col-check">
							<div className="custom-control custom-control-sm custom-checkbox notext">
								<input
								type="checkbox"
								className="custom-control-input each_invoice"
								id={user?._id}
								onChange={(e) =>handleEachInvoice(e)}
								/>
								<label
								className="custom-control-label"
								htmlFor={user?._id}  
								/>
							</div>
							</div>
							} */}
							<div className="nk-tb-col">
							{/* <span className="tb-sub">#95954</span> */}
							<span className="tb-sub table-name">
								<span className="user-avatar sm">
								{
									user?.avatar ?
									
									<img
									src={Profile[index - 1]?.name || ''}
									alt="avatar"
									/>
									:									
									<span><TwoCharacterComponent data={user?.fullname} /> </span>
								}
								</span>
								{/* <span className="user-info"> */}
								<p className="user-name ms-1 text-truncate">
								{user?.fullname} 
								</p>
								{/* </span> */}
							</span>
							</div>
							<div className="nk-tb-col">
							<span className="tb-sub text-truncate">
							{user?.username} 
							</span>
							</div>
							<div className="nk-tb-col tb-col-sm">
							<span className="tb-sub text-truncate">
							{user?.phone_no} 
							</span>
							</div>
							<div className="nk-tb-col">
							<span className="tb-lead text-truncate">
								{`${new Date(user?.createdAt)?.toLocaleDateString(
									"en-US",
									options
								)}`}
							</span>
							</div>
							<div className="nk-tb-col tb-col-md">
							<span className={`tb-sub capitalize text-primary success-btn text-truncate ${user?.status.toLowerCase() === 'active' ? 'success-btn' : 'danger-btn'}`}>
								{user?.status.toLowerCase()}
							</span>
							</div>
							
						</Link>
						);
					})
				:
				data.map((user, index) => {
						return (
						<Link  to={`${_route._admin_user}/${user?._id}`} key={user?._id} className="nk-tb-item nk-tb-item-dash">
							{/* {!dashboard && 
							<div onClick={(e)=> { e.stopPropagation()}} className="nk-tb-col nk-tb-col-check">
							<div className="custom-control custom-control-sm custom-checkbox notext">
								<input
								type="checkbox"
								className="custom-control-input each_invoice"
								id={user?._id}
								onChange={(e) =>handleEachInvoice(e)}
								/>
								<label
								className="custom-control-label"
								htmlFor={index}  
								/>
							</div>
							</div>
							} */}
							<div className="nk-tb-col">
							{/* <span className="tb-sub">#95954</span> */}
							<span className="tb-sub table-name">
								<span className="user-avatar sm">
								{
									user?.avatar ?
									
									<img
									src={Profile[index - 1]?.name || ''}
									alt="avatar"
									/>
									:									
									<span><TwoCharacterComponent data={`${user?.fullname}`} /> </span>
								}
								</span>
								{/* <span className="user-info"> */}
								<p className="user-name ms-1 text-truncate">
								{user?.fullname}
								</p>
								{/* </span> */}
							</span>
							</div>
							<div className="nk-tb-col">
							<span className="tb-sub text-truncate">
							{user?.username}
							</span>
							</div>
							<div className="nk-tb-col tb-col-sm">
							<span className="tb-sub text-truncate">
							{user?.phone_no} 
							</span>
							</div>
							<div className="nk-tb-col">
							<span className="tb-lead text-truncate">
							{`${new Date(user?.createdAt)?.toLocaleDateString(
									"en-US",
									options
								)}`}
							</span>
							</div>
							<div className="nk-tb-col tb-col-md">
							<span className={`tb-sub capitalize text-primary danger-btn text-truncate ${user?.status.toLowerCase() === 'active' ? 'success-btn' : 'danger-btn'}`}>
								{user?.status.toLowerCase()}
							</span>
							</div>
						</Link>
						);
					})
			}
			
			</div>
		</div>

		
{showDeleteModal && (
		<Modal handleClose={handleClose} showModal={showDeleteModal} >
			<div className="modal-body modal-body-lg">
				<h5 className="title">Confirm Delete</h5>
				<div className="tab-content">
					<div className="tab-pane active" id="personal" role="tabpanel">
						<p>Are you sure you want to delete User</p>
					</div>
					{/* .tab-pane */}
				</div>
				<div className="col-12 mt-4">
					<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
						<li>
							<div
								onClick={()=> handleDelete()}
								className="btn btn-lg btn-primary"
							>
								{
									deleteLoading ? <Spinnar /> : 'Delete Admin'
								}
								
							</div>
						</li>
						<li>
							<div
								onClick={()=> handleClose()}
								className="clipboard-init link link-light"
							>
								Cancel
							</div>
						</li>
					</ul>
				</div>
				{/* .tab-content */}
			</div>
		</Modal>
)}

		
{showDeleteAllModal && (
		<Modal handleClose={handleClose} showModal={showDeleteModal} >
			<div className="modal-body modal-body-lg">
				<h5 className="title">Confirm Delete</h5>
				<div className="tab-content">
					<div className="tab-pane active" id="personal" role="tabpanel">
						<p>Are you sure you want to delete selected User</p>
					</div>
					{/* .tab-pane */}
				</div>
				<div className="col-12 mt-4">
					<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
						<li>
							<div
								onClick={()=> handleAllDelete()}
								className="btn btn-lg btn-primary"
							>
								{
									deleteLoading ? <Spinnar /> : 'Delete Admin'
								}
								
							</div>
						</li>
						<li>
							<div
								onClick={()=> handleClose()}
								className="clipboard-init link link-light"
							>
								Cancel
							</div>
						</li>
					</ul>
				</div>
				{/* .tab-content */}
			</div>
		</Modal>
)}
	</>

  );
}
