const _route = {
    _admin_login: '/',
    _admin_privacy_policy: '/privacy-policy',
    _admin_verifyOtp: '/verify-otp',
    _admin: '/admin',
    _admin_dashboard: '/admin/dashboard',
    _admin_user: '/admin/users',
    _admin_transaction: '/admin/transaction',
    _admin_user_view: '/admin/users/:id',
    _admin_notification: '/admin/notification',
    _admin_single_notification: '/admin/single-notification/:id',
    _admin_single_super_notification: '/admin/single-super-notification/:id',
    _admin_profile_setting: '/admin/setting/profile',
    _admin_product: '/admin/products',
    _admin_product_overview: '/admin/products/:id',
    _admin_orders: '/admin/orders',
    _admin_orders_overview: '/admin/orders/:id/:user',


    _admin_chatbox: '/admin/tradechats',
    _admin_announcement: '/admin/agent-announcement',
    _admin_user_announcement: '/admin/user-announcement',
    _admin_home_banner: '/admin/home-banner',
    _admin_product_banner: '/admin/products/banner',
    _admin_product_to_banner: '/admin/banner',
    _admin_product_banner_overview: '/admin/banner/:id',
    _admin_academy: '/admin/academy',
    _admin_academy_overview: '/admin/academy/:id',
    _admin_academy_banner: '/admin/academy/banner',
    _admin_agent: '/admin/agents',
    _admin_add_agent: '/admin/add-agents',
    _admin_agent_view: '/admin/agents/:id',
    _admin_paymentinfo: '/admin/paymentinfo',
    _admin_avatar: '/admin/avatar',
    _admin_crypto: '/admin/crypto',
    _agent: '/agent',
    _agent_dashboard: '/agent/dashboard',
    _agent_chatbox: '/agent/chatbox',
    _agent_transaction: '/agent/transaction',
    _agent_payment_info: '/agent/paymentinfo',
    _agent_avatar: '/agent/avatar',
    _admin_giftcard: '/admin/giftcard/category',
    _admin_giftcard_country: '/admin/giftcard/category/:id',
    _admin_giftcard_country_to_id: '/admin/giftcard/category/country',
    _admin_giftcard_country_id: '/admin/giftcard/category/country/:category/:id',
    _admin_orders: '/admin/orders',
    _admin_single_notif: '/admin/single-notification',
    _admin_single_super_notif: '/admin/single-super-notification',
    _not_found: '*',

}

export default _route