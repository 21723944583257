import _route from "../../constants/routes";
import './admin.css'
import UserTableList from "../../component/user/userlist";
import { useState } from "react";


export default function UserList() {
	const [list, setList] = useState(0)

	return ( 
	<>
		<div className="nk-content ">
		<div className="container-fluid">
			<div className="nk-content-inner">
			<div className="nk-content-body">
				
				{/* .nk-block */}
				<div className="nk-block">
				<div className="row g-gs">
					<div className="col-md-12">
					<div className="card h-100 rounded-5 px-2 py-3">
						<div className="nk-ecwg nk-ecwg2">
						<div className="card-inner pe-0 ps-0">
							<div className="card-title-group mt-n1 mb-2 pe-3 pe-sm-4 ps-3 ps-sm-4">
							<div className="card-title d-flex items-center">
								<h6 className="title fw-semibold fs-4 card-title text-black">Users <span className="fw-normal fs-6">( Total {list?.toLocaleString()})</span></h6>
								
					
							</div>
							{/* <p className="mb-0 text-primary">View All </p> */}
							</div>
							<UserTableList setList={setList} />
						</div>
						{/* .card-inner */}
						</div>
						{/* .nk-ecwg */}
					</div>
					{/* .card */}
					</div>
					{/* .col */}
				</div>
				{/* .row */}
				</div>
				{/* .nk-block */}
			</div>
			</div>
		</div>
		</div>

	</>

  );
}
