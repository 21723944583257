import { useEffect } from "react";
import DashboardAnalysis from "../../component/cards/dashboardanalysis";
import useGetSingleAgent from '../../hooks/staff/usegetsingleagent '
import { useParams } from "react-router-dom";


export default function AgentOverView() {
	const {getSingleAgent, data, loading} = useGetSingleAgent()
	const {id} = useParams()

console.log(id)
	const handleGetAgent = async () =>{
		await getSingleAgent(id)
	}
	useEffect(()=>{
		handleGetAgent()
	},[])

console.log(data)

	return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm mt-4">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h4 className="page-title cus-page-title">
						Agent Overview
                    </h4>
                    <div className="nk-block-des">
                      <p>An overview of Agent account.</p>
                    </div>
                  </div>
                  {/* .nk-block-head-content */}
                </div>
                {/* .nk-block-between */}
              </div>
              {/* .nk-block */}
              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-sm-6 col-lg-4 col-xxl-3">
                    <div className="card h-100">
                      <div className="card-inner my-auto">
                        <div className="team">
                          <div className="user-card user-card-s2">
                            <div className="user-avatar md user-profile-logo">
								<span>
									<img
										className="w-100"
										src="/assets/images/avatar/avatar.png"
										alt="avatar"
									/>
								</span>
                              <div className="status dot dot-lg dot-success" />
                            </div>
                            <div className="user-info">
                              <h6>{`${data?.firstname} ${data?.lastname}`}</h6>
                              <span className="sub-text text-black fw-normal">{data?.username}</span>
                              {/* <span className="sub-text text-black fw-normal">Password: Madam12#_</span> */}
                              <span className="sub-text text-black fw-normal">{data?.permission}</span>
                            </div>
                          </div>
                        </div>
                        {/* .team */}
                      </div>
                      {/* .card-inner */}
                    </div>
                    {/* .card */}
                  </div>
                  <div className="col-sm-6 col-lg-8 col-xxl-9">
                    <div className="card h-100">
                      <div className="card-inner">
                        <div className="user-details-container">
							<div className="user-details">
								<p className="mb-0">Phone:</p>
								<p>{data?.phone_no}</p>
							</div>
							<div className="user-details">
								<p className="mb-0">Address:</p>
								<p>{data?.address}</p>
							</div>
							{/* <div className="user-details">
								<p className="mb-0">Zip Code:</p>
								<p>3300056</p>
							</div> */}
							<div className="user-details">
								<p className="mb-0">Country:</p>
								<p>{data?.country}</p>
							</div>
							<div className="user-details">
								<p className="mb-0">City:</p>
								<p>{data?.city}</p>
							</div>
							<div className="user-details">
								<p className="mb-0">State/Province:</p>
								<p>{data?.state}</p>
							</div>


                        </div>
                        {/* .team */}
                      </div>
                      {/* .card-inner */}
                    </div>
                    {/* .card */}
                  </div>

                  {/* .col */}
                </div>
                {/* .row */}
              </div>
			  {/* <div className="nk-block">
				
				<div className="row g-gs">
						<DashboardAnalysis
						title="Total Users"
						value="15500"
						increase="5"
						width='3'
						/>
						<DashboardAnalysis
						title="Legendary Users"
						value="500"
						increase="-5"
						width='3'
						/>
						<DashboardAnalysis
						title="Premium Users"
						value="1500"
						increase="5"
						width='3'
						/>
						<DashboardAnalysis
						title="Basic Users"
						value="100"
						increase="-5"
						width='3'
						/>
				</div>
			  </div> */}
              {/* .nk-block */}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
