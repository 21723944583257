
import _route from "../../constants/routes";
import "./admin.css";
import NotificationsList from "../../component/agent/notificationItem";

export default function Notification() {



  return (
    <>

      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              
              {/* .nk-block-head */}
              <div className="nk-block">
    

				<NotificationsList  />
               
              </div>
              {/* .nk-block */}
            </div>
          </div>
        </div>
      </div>

	  	  
	
    </>
  );
}
