import { useEffect, useState } from "react";
import Modal from "../../helpers/modal.jsx";
import Spinnar from "../../helpers/spinnar.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import _route from "../../constants/routes.js";
import useGetAllPaybillsTransaction from "../../hooks/transaction/paybills/usegetallunlimitedpaybillstransaction.jsx";
import useUpdateTransaction from "../../hooks/transaction/paybills/useupdatetransaction.jsx";
import emptyState from '../../assets/images/oops.png'
import Skeleton from "../skeletons/skeleton.jsx";
import Profile from "../data/profile.js";
import SideModal from "../../helpers/sidemodal";
import UnderScoreRemoval from "../../helpers/underscoreremoval";
import TwoCharacterComponent from "../../helpers/twocharactercomponent.js";

export default function UnLimitedPaybillsTransactionList({userId, dashboard}) {
	const {getAllPaybillsTransaction, data, loading} = useGetAllPaybillsTransaction()
	const options = { month: "long", year: "numeric", day: "numeric" };
	const {upDateTransaction, data: updateData, loading: upDateLoading} = useUpdateTransaction()
	const [showTransaction, setTransaction] = useState(false);
	const [selectedTransaction, setSelectedTransaction] = useState(null);
	const [showDeleteModal, setDeleteModal] = useState(false)
	const [ViewModal, setViewModal] = useState(false)
	const [viewItem, setViewItem] = useState(false)
	const [showDeleteAllModal, setDeleteAllModal] = useState(false)
	const [deleteId, setDeleteId] = useState(null)
	const {id} = useParams()
	const navigate = useNavigate()
	const deleteLoading = false

	const handleGetAllAgent = async ()=>{
		await getAllPaybillsTransaction(userId)
	}

	useEffect(()=>{
		handleGetAllAgent()
		handleClose()
	}, [updateData])
	
	const handleClose =()=>{
		setDeleteModal(false)
		setDeleteAllModal(false)
		setViewModal(false)
		setTransaction(false);
		setSelectedTransaction(null);
		setViewItem(null)
		setDeleteId(null)
	}

	const handleDelete = async ()=>{
		handleClose()
	}

	const handleAllDelete = async ()=>{
		handleClose()
	}

	const handleAcceptUpdate= (id)=>{
		upDateTransaction(id, {status: 'successful'})
	}
	

	const handleDeclineUpdate= (id)=>{
		upDateTransaction(id, {status: 'rejected'})
	}

	
	const handleSelectTransaction = (transc) => {
		setTransaction(true);
		setSelectedTransaction(transc);
	};

	
	const renderKeyValuePairs = (data) => {
		const elements = [];
	  
		const traverse = (obj, prefix = "") => {

			if (obj == null || typeof obj !== "object") {
				console.warn("Invalid object passed to traverse:", obj);
				return;
			}


		  Object.entries(obj).forEach(([key, value]) => {
			// Replace "phone_number" with "customer" in the key
			const formattedKey = prefix ? `${prefix}.${key}` : key === "phone_number" ? "customer" : key;
	  
			if (value && typeof value === "object" && !Array.isArray(value)) {
			  // Recursively handle nested objects
			  traverse(value, formattedKey);
			} else {
			  // Push the key-value pair with a null-safe check
			  elements.push(
				<div className="css-cnt7qc" key={formattedKey}>
				  <div className="capitalize">
					<UnderScoreRemoval text={formattedKey} />
				  </div>
				  <div className="title">
					<span>{value != null ? value : "N/A"}</span>
				  </div>
				</div>
			  );
			}
		  });
		};
	  
		traverse(data);
		return elements;
	};


	
	return (
    <>
      <div className="table-container px-2 h-100 mx-1 mx-sm-2">
        <div className="nk-tb-list is-separate tab_contaner mt-n2">
          <div className="nk-tb-item nk-tb-head nk-tb-head-dash">
		  <div class="nk-tb-col"><span>#</span></div>
		  <div class="nk-tb-col"><span>Customer ID</span></div>
            
            <div className="nk-tb-col">
              <span>Customer Name</span>
            </div>
            <div className="nk-tb-col">
              <span>Product</span>
            </div>
            <div className="nk-tb-col tb-col-sm">
              <span>Transaction Date</span>
            </div>
            {/* <div className="nk-tb-col tb-col-sm">
              <span>action</span>
            </div> */}
            <div className="nk-tb-col tb-col-md">
              <span>Status</span>
            </div>
            <div className="nk-tb-col">
              <span>Amount</span>
            </div>
          </div>

          {loading ? (
            [1,2,3,4,5].map(()=>{
				return (
					
					<div className="nk-tb-item table-content">
					<div className="nk-tb-col no-border">
						<span className="tb-amount">
							<Skeleton type='text' />
						</span>
					 
					</div>
					<div className="nk-tb-col no-border">
					  <span className="tb-amount">
						<Skeleton type='text' />
					  </span>
					</div>
					<div className="nk-tb-col no-border">
					<div className="user-card">
						<div
						  className="user-avatar">
							<Skeleton type='avatar'/></div>
						<div className="user-info ms-1 w-100">
						  <span className="tb-lead">
							<Skeleton type='text' />
						  </span>
						</div>
						<input type="text" defaultValue="BTC" hidden />
					  </div>
					</div>
					<div className="nk-tb-col no-border">
					  <span><Skeleton type='text' /></span>
					</div>
					<div className="nk-tb-col tb-col-sm">
						<span className={`tb-sub text-primary  text-truncate ${crypto?.status?.toLowerCase() === 'successful' ? 'text-success' : crypto.status?.toLowerCase() === 'processing' ? 'text-warning' : 'text-danger' } `}>
							<Skeleton type='text' />
						</span>
					</div>
					<div className="nk-tb-col nk-tb-col-tools tb-col-sm no-border">
						<div className="dropdown w-100">
							<Skeleton type='text' />
						</div>
		
					</div>
					<div className="nk-tb-col nk-tb-col-tools tb-col-md no-border">
						<div className="dropdown w-100">
							<Skeleton type='text' />
						</div>
		
					</div>
					{/* <div className="nk-tb-col nk-tb-col-tools no-border">
						<div className="dropdown w-100">
							<Skeleton type='text' />
						</div>
		
					</div> */}
				  </div>
				)
			})
          ) : data.length < 1 ? (
            <div className="child-container">
              <div className="centered-content">
                <div className="emptystate-container">
                  {/* <div>
								<div> */}
                  <img src={emptyState} alt="no item found" />
                  <p>No Transaction</p>
                  {/* </div>
							</div> */}
                </div>
              </div>
            </div>
          ) : (
			dashboard ?
            data?.slice(0,5).map((transaction, index) => {
              return (
                <div
					//   onClick={() => {
					//     setViewItem(transaction);
					//     setViewModal(true);
					//   }}
					onClick={() => handleSelectTransaction(transaction)}
					key={transaction?._id}
					className="nk-tb-item nk-tb-item-dash cursor-pointer"
                >
				<div class="nk-tb-col"><span>{index +1}</span></div>
				<div class="nk-tb-col"><span>#{transaction?._id?.substring(0,6)}</span></div>
                  <div className="nk-tb-col">
                    {/* <span className="tb-sub">#95954</span> */}
                    <span className="tb-sub table-name">
						<span className="user-avatar sm">
						{/* {
									transaction?.user?.avatar ?
									
									<img
									src={Profile[index - 1]?.name || ''}
									alt="avatar"
									/>
									:									 */}
									<span><TwoCharacterComponent data={transaction?.user?.fullname} /> </span>
								{/* } */}
						</span>
                      {/* <span className="user-info"> */}
                      <p className="user-name ms-1 text-truncate">
					  {transaction?.user?.fullname}
                      </p>
                      {/* </span> */}
                    </span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub text-truncate">
					{transaction?.category}
                    </span>
                  </div>
                  <div className="nk-tb-col tb-col-sm">
                    <span className="tb-sub text-truncate">
					{`${new Date(transaction?.createdAt)?.toLocaleDateString(
									"en-US",
									options
								)}`}
                    </span>
                  </div>
                  <div className="nk-tb-col tb-col-sm">
                    <span className={`tb-sub text-truncate ${transaction?.status?.toLowerCase() === 'success' ? 'success-btn'  : 'danger-btn' } `}>
                      {transaction?.status}
                    </span>
                  </div>
                  <div className="nk-tb-col tb-col-md">
                    <span className={`tb-sub text-truncate`}>
						N{transaction?.bill?.amount?.toLocaleString()}
                    </span>
                  </div>
                </div>
              );
            })
			:
            data?.slice(0,5).map((transaction, index) => {
              return (
                <div
					//   onClick={() => {
					//     setViewItem(transaction);
					//     setViewModal(true);
					//   }}
					onClick={() => handleSelectTransaction(transaction)}
					key={transaction?._id}
					className="nk-tb-item nk-tb-item-dash cursor-pointer"
                >
				<div class="nk-tb-col"><span>{index +1}</span></div>
				<div class="nk-tb-col"><span>#{transaction?._id?.substring(0,6)}</span></div>
                  <div className="nk-tb-col">
                    {/* <span className="tb-sub">#95954</span> */}
                    <span className="tb-sub table-name">
						<span className="user-avatar sm">
						{/* {
									transaction?.user?.avatar ?
									
									<img
									src={Profile[index - 1]?.name || ''}
									alt="avatar"
									/>
									:									 */}
									<span><TwoCharacterComponent data={transaction?.user?.fullname} /> </span>
								{/* } */}
						</span>
                      {/* <span className="user-info"> */}
                      <p className="user-name ms-1 text-truncate">
					  {transaction?.user?.fullname}
                      </p>
                      {/* </span> */}
                    </span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub text-truncate">
					{transaction?.category}
                    </span>
                  </div>
                  <div className="nk-tb-col tb-col-sm">
                    <span className="tb-sub text-truncate">
					{`${new Date(transaction?.createdAt)?.toLocaleDateString(
									"en-US",
									options
								)}`}
                    </span>
                  </div>
                  <div className="nk-tb-col tb-col-sm">
                    <span className={`tb-sub text-truncate ${transaction?.status?.toLowerCase() === 'success' ? 'success-btn'  : 'danger-btn' } `}>
                      {transaction?.status}
                    </span>
                  </div>
                  <div className="nk-tb-col tb-col-md">
                    <span className={`tb-sub text-truncate`}>
						N{transaction?.bill?.amount?.toLocaleString()}
                    </span>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>

      {showDeleteModal && (
        <Modal handleClose={handleClose} showModal={showDeleteModal}>
          <div className="modal-body modal-body-lg">
            <h5 className="title">Confirm Delete</h5>
            <div className="tab-content">
              <div className="tab-pane active" id="personal" role="tabpanel">
                <p>Are you sure you want to delete User</p>
              </div>
              {/* .tab-pane */}
            </div>
            <div className="col-12 mt-4">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                <li>
                  <div
                    onClick={() => handleDelete()}
                    className="btn btn-lg btn-primary"
                  >
                    {deleteLoading ? <Spinnar /> : "Delete Admin"}
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => handleClose()}
                    className="clipboard-init link link-light"
                  >
                    Cancel
                  </div>
                </li>
              </ul>
            </div>
            {/* .tab-content */}
          </div>
        </Modal>
      )}

      {/* {ViewModal && (
        <Modal handleClose={handleClose} showModal={ViewModal}>
          <div className="modal-body modal-body-lg text-center">
            <div className="nk-modal">
              <em
                style={{
                  background: `url("${viewItem?.crypto?.logo?.url}") `,
                  
                }}
                className="nk-modal-icon icon icon-circle icon-circle-xxl ni  bg-success"
              />
              <h4 className="nk-modal-title">{viewItem.name}</h4>
              <div className="nk-modal-text">
                <div className="caption-text">
                  <strong className="text-black">Client Details</strong>
				  <br />
                  Client Name:
                  <strong> {viewItem?.user?.fullname}</strong>
                  <br />
                  Client Email:
                  <strong> {viewItem?.user?.usename}</strong>
                  <br />
                  <strong className="text-black">{viewItem?.user?.username}</strong>
				  <br />
                  Client Phone:
                  <strong>{viewItem?.user?.phone_no}</strong>
                  <br />
                </div>
                <div className="caption-text">
                  <strong className="text-black">Crypto Details</strong>
				  <br />
                  Crypto Name:
                  <strong> {viewItem?.crypto?.name}</strong>
                  <br />
                  Transaction Action:
                  <strong> {viewItem?.action}</strong>
                  <br />
                  <strong className="text-black">Amount Details</strong>
				  <br />
                  Amount of Crypto:
                  <strong>{viewItem?.amount?.crypto} {viewItem?.crypto?.shortCode}</strong>
                  <br />
                  Amount in Naira:
                  <strong> ₦{viewItem?.amount?.naira}</strong>
                  <br />
                  Amount in Dollar:
                  <strong> ${viewItem?.amount?.dollar}</strong>
                  <br />
                </div>
                <div>
                  <small>Proof of payment</small>
                  {/* <p>{viewItem?.recievingAddress?.wallet}</p> 
                  <div
                    style={{
                      background: `url("${viewItem?.proof_of_payment?.url}")`,
                      height: `15rem`,
                      width: `10rem`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
					  backgroundRepeat: 'no-repeat',
                      margin: "0 auto",
                    }}
                  ></div>
                </div>
              </div>
			  <div className="d-flex justify-center buttton-action items-center gab-2">
				<div className="nk-modal-action">
					<button
						onClick={()=> handleDeclineUpdate(viewItem?._id)}
						disabled={viewItem?.status.toLowerCase() === 'rejected'}
						className="btn btn-lg btn-mw btn-danger"
					>
					{upDateLoading ? <Spinnar /> : 'Decline'}
					</button>
				</div>
				<div className="nk-modal-action">
					<button
						onClick={()=> handleAcceptUpdate(viewItem?._id)}
						disabled={viewItem.status.toLowerCase() === 'successful'}
						className="btn btn-lg btn-mw btn-primary"
					>
					{upDateLoading ? <Spinnar /> : 'Approve'}
					</button>
				</div>
			  </div>
            </div>
          </div>
          <div className="modal-footer bg-lighter">
            <div className="text-center w-100">
              {/* <p>
                    Earn upto $25 for each friend your refer!{" "}
                    <a href="javascript:void(0)">Invite friends</a>
                  </p>
            </div>
          </div>
        </Modal>
      )} */}

	  

<SideModal
        handleClose={handleClose}
        showModal={showTransaction}
        myStyle={"active-view"}
      >
        <div data-testid="transaction-modal" className="css-wjk5yo">
          <div className="css-1x2qt9t">
            <div className="title"> {selectedTransaction?.network?.split(" ")[0]} {selectedTransaction?.category}</div>
          </div>
          <div className="css-xr82n9">
            <div>
              <div className="amount debit ">₦{selectedTransaction?.bill?.amount}</div>
              <div>Total Amount</div>
            </div>
			<div
  className={`center css-13b6tx1 ${
    selectedTransaction?.status?.toLowerCase() === "success"
      ? "text-success"
      : selectedTransaction?.status?.toLowerCase() === "processing"
      ? "text-warning"
      : "text-danger"
  }`}
>
			{selectedTransaction?.status}</div>
          </div>
          <div className="css-16mevgv">
            <div className="css-oggppy">
              <button
                color="#161616"
                className="pill  css-1erdyoi"
                data-testid="pillbutton"
                aria-describedby="tooltip"
              >
                Transaction Details
              </button>
            </div>
			{selectedTransaction && renderKeyValuePairs(selectedTransaction?.bill)}
          </div>
		  
		  {/* {selectedTransaction && <div className="d-flex justify-center buttton-action items-center gab-2">
				<div className="nk-modal-action">
					<button
						onClick={()=> handleDeclineUpdate(selectedTransaction?._id)}
						disabled={selectedTransaction?.status.toLowerCase() === 'rejected'}
						className="btn btn-lg btn-mw btn-danger"
					>
					{upDateLoading ? <Spinnar /> : 'Decline'}
					</button>
				</div>
				<div className="nk-modal-action">
					<button
						onClick={()=> handleAcceptUpdate(selectedTransaction?._id)}
						disabled={selectedTransaction.status.toLowerCase() === 'successful'}
						className="btn btn-lg btn-mw btn-primary"
					>
					{upDateLoading ? <Spinnar /> : 'Approve'}
					</button>
				</div>
			  </div>} */}
        </div>
      </SideModal>

      {showDeleteAllModal && (
        <Modal handleClose={handleClose} showModal={showDeleteModal}>
          <div className="modal-body modal-body-lg">
            <h5 className="title">Confirm Delete</h5>
            <div className="tab-content">
              <div className="tab-pane active" id="personal" role="tabpanel">
                <p>Are you sure you want to delete selected User</p>
              </div>
              {/* .tab-pane */}
            </div>
            <div className="col-12 mt-4">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                <li>
                  <div
                    onClick={() => handleAllDelete()}
                    className="btn btn-lg btn-primary"
                  >
                    {deleteLoading ? <Spinnar /> : "Delete Admin"}
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => handleClose()}
                    className="clipboard-init link link-light"
                  >
                    Cancel
                  </div>
                </li>
              </ul>
            </div>
            {/* .tab-content */}
          </div>
        </Modal>
      )}
    </>
  );
}
