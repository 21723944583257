import { useEffect, useState } from "react";
import _route from "../../constants/routes";
import "./admin.css";
import EditProductForm from "../../component/product/editproductbanner";
import useGetSingleProduct from "../../hooks/product/usegetsingleproduct";
import useUpdateProduct from "../../hooks/product/useupdateproduct";
import useDeleteProduct from "../../hooks/product/usedeleteproduct";
import Spinnar from "../../helpers/spinnar";
import { Link, useNavigate, useParams } from "react-router-dom";
import ModalWithHeader from "../../component/modalwithtitle"

export default function ProductOverVIew() {
	const navigate = useNavigate()
	const [addModal, setAddModal] = useState(false)
	const {id} = useParams()
	const options = { month: 'long', year: 'numeric', day: 'numeric' };
	const [editModal, setEditAddModal] = useState(false)
	const [deleteId, setDeleteId] = useState(null)
	const [deleteModal, setDeleteModal] = useState(false)
	const [editItem, setEditItem] = useState(null)
	const {getSingleProduct, data, loading } = useGetSingleProduct();
	const {updateProduct, data: updateData, loading: updateLoading } = useUpdateProduct();
	const {deleteProduct, data: deleteData, loading: deleteLoading} = useDeleteProduct()
	

	const handleClose = () => {
		setAddModal(false)
		setDeleteId(null)
		setEditItem(null)
		setDeleteModal(false)
		setEditAddModal(false)
	};

	const handleUpdate = (details) => {
		updateProduct(details, id)
	};

	const handleDelete = () => {
		deleteProduct(id)
	};

	useEffect(()=>{
		if(deleteData){
			navigate(_route._admin_product)
		}
	}, [deleteData])

	useEffect(()=>{
		handleClose()
	}, [updateData])

	const handleGetProduct = async () => {
		await getSingleProduct(id);
	};

	useEffect(() => {
		handleGetProduct()
	}, [updateData]);
	



	console.log(data)

  return (
    <>

		<div className="nk-content tb-ff">
			<div className="container-fluid">
				<div className="nk-content-inner">
				<div className="nk-content-body">
					<div className="nk-block-head nk-block-head-sm">
					<div className="nk-block-between g-3">
						<div className="nk-block-head-content">
						<h3 className="nk-block-title page-title tb-ff">View Product</h3>
						{/* <p className="tb-ff size-md">Notifications / Broadcast</p>  */}
						</div>{/* .nk-block-head-content */}
						<div className="nk-block-head-content">
						<a onClick={()=> navigate(-1)} className="btn btn-outline-light bg-white d-none d-sm-inline-flex bg-paybond"><em className="icon ni ni-arrow-left text-white" /><span className="tb-ff text-white">Back</span></a>
						<a onClick={()=> navigate(-1)} className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"><em className="icon ni ni-arrow-left" /></a>
						</div>{/* .nk-block-head-content */}
					</div>{/* .nk-block-between */}
					</div>{/* .nk-block-head */}
					<div className="nk-block tb-ff">
					<div className="row gy-5">
						<div className="col-lg-7">
						<div className="nk-block-head">
							<div className="nk-block-head-content">
							<h5 className="nk-block-title title tb-ff">Product Image</h5>
							{/* <p>Basic announcement content</p> */}
							</div>
						</div>
						<div className="card card-bordered">
							<div className="pad-b-sides w-100">
								<img src={data?.image?.url} alt={data?.title} />
							</div>
						</div>
						</div>
						<div className="col-lg-5">
						<div className="nk-block-head">
							<div className="nk-block-head-content">
							<h5 className="nk-block-title title tb-ff"> Other information</h5>
							<p>Detailed information</p>
							</div>
						</div>
						<div className="card card-bordered">
							<ul className="data-list is-compact">
							<li className="data-item">
								<div className="data-col">
								<div className="data-label">Title</div>
								<div className="data-value">{data?.title}</div>
								</div>
							</li>
							<li className="data-item">
								<div className="data-col">
								<div className="data-label">SUbTitle</div>
								<div className="data-value">{data?.sub_title}</div>
								</div>
							</li>
							<li className="data-item">
								<div className="data-col">
								<div className="data-label">Description</div>
								<div className="data-value">{data?.description}</div>
								</div>
							</li>
							<li className="data-item">
								<div className="data-col">
								<div className="data-label">Price</div>
								<div className="data-value"><span className="currency">₦</span>{data?.price}</div>
								</div>
							</li>
							<li className="data-item">
								<div className="data-col">
								<div className="data-label">Quantity</div>
								<div className="data-value">{data?.quantity}</div>
								</div>
							</li>
							</ul>
						</div>
						<div class="modal-footer gap-3">
							<button onClick={()=> {setEditItem(data); setEditAddModal(true)}} type="button" class="btn btn-primary" data-dismiss="modal">Edit</button>
							<button onClick={() => {setDeleteId(data._id); setDeleteModal(true)}} class="btn btn-danger">Delete</button>
						</div>
						</div>
					</div>
					</div>    
				</div>
				</div>
			</div>
		</div>



	 

	  	  
		{editModal && (
			<ModalWithHeader
			handleClose={handleClose}
			showModal={editModal}
			header='Edit Product'
			>
			
			<EditProductForm editCrypto={editItem} setEditCrypto={setEditItem}  handleUpdateCrypto={handleUpdate} loading={updateLoading} />
				<div className="modal-footer bg-light">
				<span className="sub-text">
					This adds a cryptocurrency to the listings on the website
				</span>
				</div>
			</ModalWithHeader>
		)}

		{deleteModal && (
			<ModalWithHeader
			handleClose={handleClose}
			showModal={deleteModal}
			header='Are you sure?'
			>
				<div class="modal-body">
					<p>Do you really want to delete this product?
						This process cannot be reversed.</p>

				</div>
				<div class="modal-footer">
					<button onClick={()=> handleClose()} type="button" class="btn btn-primary" data-dismiss="modal">Cancel</button>

					<button onClick={() => handleDelete(deleteId)} class="btn btn-danger">{deleteLoading ? <Spinnar /> : 'Delete'}
					</button>


				</div>
			</ModalWithHeader>
		)}
    </>
  );
}
