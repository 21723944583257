import CryptoJS from 'crypto-js';

const HashPasswordKey = process.env.REACT_APP_BASE_HASH_KEY;

function interCeptTokenFromResponse(res) {
	if (res?.refreshToken || res?.token) {
		const { refreshToken, token } = res;
		// Encrypt tokens before storing robert 
		let encrptToken = null;
		let encrptRefreshToken = null;
		if(token){
		encrptToken = CryptoJS.AES.encrypt(JSON.stringify(token), HashPasswordKey).toString();
		}
		if(refreshToken){
		encrptRefreshToken = CryptoJS.AES.encrypt(JSON.stringify(refreshToken), HashPasswordKey).toString();
		}
		// Set tokens to local storage
		if (encrptRefreshToken) {
			console.log("refreshToken updated", refreshToken  )
			localStorage.setItem('refreshToken', encrptRefreshToken);
		}
		
		if (encrptToken) {
			console.log("token updated", token)
			localStorage.setItem('authorization', encrptToken);
		}
	  }
}

export default interCeptTokenFromResponse;
