import { useEffect, useState } from "react";
import Modal from "../../helpers/modal";
import Spinnar from "../../helpers/spinnar";
import { Link, useNavigate, useParams } from "react-router-dom";
import _route from "../../constants/routes";
import useUpdateTransaction from "../../hooks/transaction/paybills/useupdatetransaction.jsx";
import emptyState from '../../assets/images/oops.png'
import Skeleton from "../skeletons/skeleton.jsx";
import Profile from "../data/profile";
import useGetAllOrderTransaction from "../../hooks/transaction/groceries/usegetallunlimitedordertransaction.jsx";
import TwoCharacterComponent from "../../helpers/twocharactercomponent.js";

export default function UnLimitedCryptoTransactionList({userId, dashboard}) {
	const {getAllOrderTransaction, data, loading} = useGetAllOrderTransaction()
	const options = { month: "long", year: "numeric", day: "numeric" };
	const {upDateTransaction, data: updateData, loading: upDateLoading} = useUpdateTransaction()
	const [showDeleteModal, setDeleteModal] = useState(false)
	const [ViewModal, setViewModal] = useState(false)
	const [viewItem, setViewItem] = useState(false)
	const [showDeleteAllModal, setDeleteAllModal] = useState(false)
	const [deleteId, setDeleteId] = useState(null)
	const {id} = useParams()
	const navigate = useNavigate()
	const deleteLoading = false

	const handleGetAllAgent = async ()=>{
		await getAllOrderTransaction(userId)
	}

	useEffect(()=>{
		handleGetAllAgent()
		handleClose()
	}, [updateData])
	
	const handleClose =()=>{
		setDeleteModal(false)
		setDeleteAllModal(false)
		setViewModal(false)
		setViewItem(null)
		setDeleteId(null)
	}

	const handleDelete = async ()=>{
		handleClose()
	}

	const handleAllDelete = async ()=>{
		handleClose()
	}

	const handleAcceptUpdate= (id)=>{
		upDateTransaction(id, {status: 'successful'})
	}
	

	const handleDeclineUpdate= (id)=>{
		upDateTransaction(id, {status: 'rejected'})
	}

	return (
    <>
      <div className="table-container px-2 h-100 mx-1 mx-sm-2">
        <div className="nk-tb-list is-separate tab_contaner mt-n2">
          <div className="nk-tb-item nk-tb-head nk-tb-head-dash">
		  <div class="nk-tb-col"><span>#</span></div>
		  <div class="nk-tb-col"><span>Product ID</span></div>
            
            <div className="nk-tb-col">
              <span>Customer Name</span>
            </div>
            <div className="nk-tb-col">
              <span>Product</span>
            </div>
            <div className="nk-tb-col tb-col-sm">
              <span>Transaction Date</span>
            </div>
            {/* <div className="nk-tb-col tb-col-sm">
              <span>action</span>
            </div> */}
            <div className="nk-tb-col tb-col-md">
              <span>Status</span>
            </div>
            <div className="nk-tb-col">
              <span>Amount</span>
            </div>
          </div>

          {loading ? (
            [1,2,3,4,5].map(()=>{
				return (
					
					<div className="nk-tb-item table-content">
					<div className="nk-tb-col no-border">
					<Skeleton type='text' />
					</div>
					<div className="nk-tb-col tb-col-mb no-border">
					  <span className="tb-amount">
						<Skeleton type='text' />
					  </span>
					</div>
					<div className="nk-tb-col tb-col-md no-border">
					  
					<div className="user-card">
						<div
						  className="user-avatar"
						><Skeleton type='avatar'/></div>
						<div className="user-info w-100">
						  <span className="tb-lead">
							<Skeleton type='text' />
						  </span>
						</div>
						<input type="text" defaultValue="BTC" hidden />
					  </div>
					</div>
					<div className="nk-tb-col tb-col-md no-border">
					  <span><Skeleton type='text' /></span>
					</div>
					<div className="nk-tb-col tb-col-lg no-border">
					  <span><Skeleton type='text' /></span>
					</div>
					<div className="nk-tb-col tb-col-md">
						<span className={`tb-sub text-primary  text-truncate ${crypto?.status?.toLowerCase() === 'successful' ? 'text-success' : crypto.status?.toLowerCase() === 'processing' ? 'text-warning' : 'text-danger' } `}>
							<Skeleton type='text' />
						</span>
					</div>
					<div className="nk-tb-col nk-tb-col-tools no-border">
						<div className="dropdown w-100">
							<Skeleton type='text' />
						</div>
		
					</div>
					<div className="nk-tb-col nk-tb-col-tools no-border">
						<div className="dropdown w-100">
							<Skeleton type='text' />
						</div>
		
					</div>
				  </div>
				)
			})
          ) : data.length < 1 ? (
            <div className="child-container">
              <div className="centered-content">
                <div className="emptystate-container">
                  {/* <div>
								<div> */}
                  <img src={emptyState} alt="no item found" />
                  <p>No Transaction</p>
                  {/* </div>
							</div> */}
                </div>
              </div>
            </div>
          ) : (
			dashboard ?
            data?.slice(0,5).map((transaction, index) => {
              return (
                <Link
                 /* onClick={() => {
                    setViewItem(transaction);
                    setViewModal(true);
                  }} */
				 to={`${_route._admin_orders}/${transaction?._id}/${transaction?.user?._id}`}
                  key={index}
                  className="nk-tb-item nk-tb-item-dash"
                >
				<div class="nk-tb-col"><span>{index +1}</span></div>
				<div class="nk-tb-col"><span>#{transaction?._id?.substring(0,6)}</span></div>
                  <div className="nk-tb-col">
                    {/* <span className="tb-sub">#95954</span> */}
                    <span className="tb-sub table-name">
					<span className="user-avatar sm">
						{/* {
									transaction?.user?.avatar ?
									
									<img
									src={Profile[index - 1]?.name || ''}
									alt="avatar"
									/>
									:									 */}
									<span><TwoCharacterComponent data={transaction?.user?.fullname} /> </span>
								{/* } */}
						</span>
                      {/* <span className="user-info"> */}
                      <p className="user-name ms-1 text-truncate">
						{transaction?.user?.fullname}
                      </p>
                      {/* </span> */}
                    </span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub text-truncate">
						{transaction?.payment_method}
                    </span>
                  </div>
                  <div className="nk-tb-col tb-col-sm">
                    <span className="tb-sub text-truncate">
					{`${new Date(transaction?.createdAt)?.toLocaleDateString(
									"en-US",
									options
								)}`}
                    </span>
                  </div>
                  <div className="nk-tb-col tb-col-sm">
                    <span className={`tb-sub success-btn text-truncate ${transaction?.status?.toLowerCase() === 'successful' ? 'success-btn' : transaction.status?.toLowerCase() === 'processing' ? 'danger-btn' : 'danger-btn' } `}>
                      {transaction?.status}
                    </span>
                  </div>
                  <div className="nk-tb-col tb-col-md">
                    <span className={`tb-sub text-truncate`}>
						N{transaction?.total_amount?.toLocaleString()}
                    </span>
                  </div>
                </Link>
              );
            })
			:
			data?.map((transaction, index) => {
				return (
				  <Link
				   /* onClick={() => {
					  setViewItem(transaction);
					  setViewModal(true);
					}} */
				   to={`${_route._admin_orders}/${transaction?._id}/${transaction?.user?._id}`}
					key={index}
					className="nk-tb-item nk-tb-item-dash"
				  >
				  <div class="nk-tb-col"><span>{index +1}</span></div>
				  <div class="nk-tb-col"><span>#{transaction?._id?.substring(0,6)}</span></div>
					<div className="nk-tb-col">
					  {/* <span className="tb-sub">#95954</span> */}
					  <span className="tb-sub table-name">
					  <span className="user-avatar sm">
						  {/* {
									  transaction?.user?.avatar ?
									  
									  <img
									  src={Profile[index - 1]?.name || ''}
									  alt="avatar"
									  />
									  :									 */}
									  <span><TwoCharacterComponent data={transaction?.user?.fullname} /> </span>
								  {/* } */}
						  </span>
						{/* <span className="user-info"> */}
						<p className="user-name ms-1 text-truncate">
						  {transaction?.user?.fullname}
						</p>
						{/* </span> */}
					  </span>
					</div>
					<div className="nk-tb-col">
					  <span className="tb-sub text-truncate">
						  {transaction?.payment_method}
					  </span>
					</div>
					<div className="nk-tb-col tb-col-sm">
					  <span className="tb-sub text-truncate">
					  {`${new Date(transaction?.createdAt)?.toLocaleDateString(
									  "en-US",
									  options
								  )}`}
					  </span>
					</div>
					<div className="nk-tb-col tb-col-sm">
					  <span className={`tb-sub success-btn text-truncate ${transaction?.status?.toLowerCase() === 'successful' ? 'success-btn' : transaction.status?.toLowerCase() === 'processing' ? 'danger-btn' : 'danger-btn' } `}>
						{transaction?.status}
					  </span>
					</div>
					<div className="nk-tb-col tb-col-md">
					  <span className={`tb-sub text-truncate`}>
						  N{transaction?.total_amount?.toLocaleString()}
					  </span>
					</div>
				  </Link>
				);
			  })
          )}
        </div>
      </div>

      {showDeleteModal && (
        <Modal handleClose={handleClose} showModal={showDeleteModal}>
          <div className="modal-body modal-body-lg">
            <h5 className="title">Confirm Delete</h5>
            <div className="tab-content">
              <div className="tab-pane active" id="personal" role="tabpanel">
                <p>Are you sure you want to delete User</p>
              </div>
              {/* .tab-pane */}
            </div>
            <div className="col-12 mt-4">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                <li>
                  <div
                    onClick={() => handleDelete()}
                    className="btn btn-lg btn-primary"
                  >
                    {deleteLoading ? <Spinnar /> : "Delete Admin"}
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => handleClose()}
                    className="clipboard-init link link-light"
                  >
                    Cancel
                  </div>
                </li>
              </ul>
            </div>
            {/* .tab-content */}
          </div>
        </Modal>
      )}

      {ViewModal && (
        <Modal handleClose={handleClose} showModal={ViewModal}>
          <div className="modal-body modal-body-lg text-center">
            <div className="nk-modal">
              <em
                style={{
                  background: `url("${viewItem?.crypto?.logo?.url}") `,
                  
                }}
                className="nk-modal-icon icon icon-circle icon-circle-xxl ni  bg-success"
              />
              <h4 className="nk-modal-title">{viewItem.name}</h4>
              <div className="nk-modal-text">
                <div className="caption-text">
                  <strong className="text-black">Client Details</strong>
				  <br />
                  Client Name:
                  <strong> {`${viewItem?.user?.firstname} ${viewItem?.user?.lastname}`}</strong>
                  <br />
                  Client Email:
                  <strong> {viewItem?.user?.usename}</strong>
                  <br />
                  <strong className="text-black">{viewItem?.user?.username}</strong>
				  <br />
                  Client Phone:
                  <strong>{viewItem?.user?.phone_no}</strong>
                  <br />
                </div>
                <div className="caption-text">
                  <strong className="text-black">Crypto Details</strong>
				  <br />
                  Crypto Name:
                  <strong> {viewItem?.crypto?.name}</strong>
                  <br />
                  Transaction Action:
                  <strong> {viewItem?.action}</strong>
                  <br />
                  <strong className="text-black">Amount Details</strong>
				  <br />
                  Amount of Crypto:
                  <strong>{viewItem?.amount?.crypto} {viewItem?.crypto?.shortCode}</strong>
                  <br />
                  Amount in Naira:
                  <strong> ₦{viewItem?.amount?.naira}</strong>
                  <br />
                  Amount in Dollar:
                  <strong> ${viewItem?.amount?.dollar}</strong>
                  <br />
                </div>
                <div>
                  <small>Proof of payment</small>
                  {/* <p>{viewItem?.recievingAddress?.wallet}</p> */}
                  <div
                    style={{
                      background: `url("${viewItem?.proof_of_payment.url}")`,
                      height: `15rem`,
                      width: `10rem`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
					  backgroundRepeat: 'no-repeat',
                      margin: "0 auto",
                    }}
                  ></div>
                </div>
              </div>
			  <div className="d-flex justify-center buttton-action items-center gab-2">
				<div className="nk-modal-action">
					<button
						onClick={()=> handleDeclineUpdate(viewItem._id)}
						disabled={viewItem?.status.toLowerCase() === 'rejected'}
						className="btn btn-lg btn-mw btn-danger"
					>
					{upDateLoading ? <Spinnar /> : 'Decline'}
					</button>
				</div>
				<div className="nk-modal-action">
					<button
						onClick={()=> handleAcceptUpdate(viewItem._id)}
						disabled={viewItem.status.toLowerCase() === 'successful'}
						className="btn btn-lg btn-mw btn-primary"
					>
					{upDateLoading ? <Spinnar /> : 'Approve'}
					</button>
				</div>
			  </div>
            </div>
          </div>
          <div className="modal-footer bg-lighter">
            <div className="text-center w-100">
              {/* <p>
                    Earn upto $25 for each friend your refer!{" "}
                    <a href="javascript:void(0)">Invite friends</a>
                  </p> */}
            </div>
          </div>
        </Modal>
      )}

      {showDeleteAllModal && (
        <Modal handleClose={handleClose} showModal={showDeleteModal}>
          <div className="modal-body modal-body-lg">
            <h5 className="title">Confirm Delete</h5>
            <div className="tab-content">
              <div className="tab-pane active" id="personal" role="tabpanel">
                <p>Are you sure you want to delete selected User</p>
              </div>
              {/* .tab-pane */}
            </div>
            <div className="col-12 mt-4">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                <li>
                  <div
                    onClick={() => handleAllDelete()}
                    className="btn btn-lg btn-primary"
                  >
                    {deleteLoading ? <Spinnar /> : "Delete Admin"}
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => handleClose()}
                    className="clipboard-init link link-light"
                  >
                    Cancel
                  </div>
                </li>
              </ul>
            </div>
            {/* .tab-content */}
          </div>
        </Modal>
      )}
    </>
  );
}
