import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import _route from '../constants/routes';
import OTPInput from 'react-otp-input';
import Spinnar from '../component/spinnar'
import useVerifyUSer from '../hooks/auth/useotp';
import Countdown from '../helpers/countdown';
import useResendOtp from '../hooks/auth/resendotp';

export default function VerifyOtp() {
	const {verifyOtp, loading} = useVerifyUSer()
	const {resendOtp, loading: otpLoading} = useResendOtp()
    const [OTP, setOTP] = useState("");
	const navigate = useNavigate()
	const [startCountdown, setStartCountdown] = useState(false);

	const handleSubmit = async (e)=>{
		e.preventDefault()
		const formData = {
			otp: OTP,
		}
		console.log("otp", formData)
		const loginSuccess = await verifyOtp(formData);	
		// If login is successful, navigate to the dashboard and reload the page
		if (loginSuccess) {
			navigate(_route._admin_dashboard);
			window.location.reload(); 
		}
	}

	
    const handleresendOtp = async (e) =>{
		e.preventDefault()
		if(!otpLoading){
		//   await forgotPassword(email) && setStartCountdown(true); Success('OTP sent Successfully');
            const result = await resendOtp();
            if (result) {
                setStartCountdown(true);
                // Success('OTP sent Successfully');
            } 
		  
		}
	}

  return (
    <>
      <div className="nk-app-root">
        {/* main @s */}
        <div className="nk-main ">
          <span></span>
          {/* wrap @s */}
          <div className="nk-wrap auth-container nk-wrap-nosidebar">
            {/* content @s */}

			  
			  <div className="nk-content ">
  <div className="nk-block nk-block-middle justify-content-center align-items-center nk-auth-body nk-central wide-sm">
    <div className="brand-logo pb-4 text-center">
	  <Link to={_route._admin_dashboard} className="logo-link">
        <img className="logo-dark logo-img logo-img" src="/assets/images/logo.png" alt="logo" />
	</Link>
    </div>
    <div className="card curved col-md-8 col-lg-7">
      <div className="card-inner card-inner-lg">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h5 className="nk-block-title">OTP Verification</h5>
            <div className="nk-block-des">
              <p>Please enter the 6 digit code we sent to your email</p>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-note-s2 otp-code-des"> OTP code</div>
          <div className="form-group">
            <div className="otp-container"> 
              <div id="inputs" className="otp-inputs"> 
				<OTPInput
                      value={OTP}
                      onChange={setOTP}
                      numInputs={6}
					  inputStyle="otp-input"
					  skipDefaultStyles={true}
                      renderSeparator={<span> </span>}
					  renderInput={(props) => <input  {...props} />}
                    />
              </div> 
            </div> 
          </div>
          <div className="form-note-s2 text-center"> Didn’t receive OTP? {otpLoading ?<span className='couter-spinnar' > <Spinnar /> </span>: startCountdown ? <Countdown startCountdown={startCountdown} setStartCountdown={setStartCountdown} /> :  <span onClick={handleresendOtp} id="sendOTP">Resend OTP</span> } </div>
          <div className="form-group">
            <button disabled={loading} className="auth-btn btn btn-lg btn-primary btn-block">{loading ? <Spinnar /> : "Verify"}</button>
          </div>
          <input id="email" name="email" className="hidden" />
        </form>
      </div>
    </div>
  </div>
</div>

              {/* .nk-split */}
            {/* wrap @e */}
          </div>
          {/* content @e */}
        </div>
        {/* main @e */}
      </div>
    </>
  );
}
