import { useEffect, useState } from "react";
import _route from "../../constants/routes";
import "./admin.css";
import AddProduct from "../../component/product/addProductBanner";
import EditProductForm from "../../component/product/editproductbanner";
import useGetAllProduct from "../../hooks/product/usegetproduct";
import usePostProduct from "../../hooks/product/usecreateproduct";
import useUpdateProduct from "../../hooks/product/useupdateproduct";
import useDeleteProduct from "../../hooks/product/usedeleteproduct";
import useGetAllBanner from "../../hooks/banner/usegetbannerproduct";
import usePostBanner from "../../hooks/banner/usecreatebannerproduct";
import Spinnar from "../../helpers/spinnar";
import emptyState from '../../assets/images/oops.png'
import { Link } from "react-router-dom";
import ModalWithHeader from "../../component/modalwithtitle"
import Skeleton from "../../component/skeletons/skeleton";

export default function Product() {
	const [addModal, setAddModal] = useState(false)
	const options = { month: 'long', year: 'numeric', day: 'numeric' };
  // const [activeTab, setActiveTab] = useState(0)
  const [mobileAdd, setMobileAdd] = useState(false)
  const [editModal, setEditAddModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [activeTab, setActiveTab] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false)
  const [editItem, setEditItem] = useState(null)
  const {updateProduct, data: updateData, loading: updateLoading} = useUpdateProduct()
  const {deleteProduct, data: deleteData, loading: deleteLoading} = useDeleteProduct()
  const [bannerdetails, setBannerDetails] = useState([
    {
      banner: null,
      image: null,
      title: "",
      description: "",
    },
  ]);
  const [productdetails, setProductDetails] = useState([
    {
      product: null,
      image: null,
      title: "",
      description: "",
      price: "",
      sub_title: "",
    },
  ]);
  const [banerNxt, setBannerNxt] = useState(0);
  const { getAllProduct, data, loading } = useGetAllProduct();
//   const {getAllBannerProduct, data: bannerData, loading: bannerLoading} = useGetAllBanner();
  const {postBanner, data: bannerPostData, loading: bannerPostLoading} = usePostBanner();
  const {
    postProduct,
    data: postData,
    loading: postLoading,
  } = usePostProduct();

//   const addNewBanner = () => {
//     setBannerDetails((prevDetails) => [
//       ...prevDetails,
//       {
//         banner: null,
//         title: "",
//         description: "",
//       },
//     ]);
//   };

//   const addNewProduct = () => {
//     setProductDetails((prevDetails) => [
//       ...prevDetails,
//       {
//         product: null,
//         image: null,
//         title: "",
//         description: "",
//         price: "",
//         size: [],
//         color: [],
//       },
//     ]);
//   };

  const handleClose = () => {
	setAddModal(false)
    setActiveTab(null);
	setDeleteId(null)
	setEditItem(null)
	setDeleteModal(false)
	setEditAddModal(false)
    setBannerNxt(0);
    setProductDetails([
      {
        product: null,
        image: null,
        title: "",
        description: "",
        price: "",
        sub_title: "",
      },
    ]);
    // setBannerDetails([
    //   {
    //     banner: null,
    //     image: null,
    //     title: "",
    //     description: "",
    //   },
    // ]);
  };

  const handleSave = async (details) => {
    await postProduct(details);
  };

  const handleUpdate = async (details) => {
    await updateProduct(details, editItem._id) && handleClose();
  };

//   const handleSaveBanner = () => {
//     // var product = []
//     const formData = new FormData();
    
// 	const itemsArray = bannerdetails.map((item, index) => ({
// 		page: 'store',
// 		description: item.description,
// 		name: item.title,
// 		type: 'store',
// 	}));

// 	bannerdetails.forEach((item, index) => (
// 		formData.append('image', item.banner)
// 	));

// 	formData.append('items', JSON.stringify(itemsArray));
	
//     postBanner(formData);
//   };

  const handleDelete = async () => {
	await deleteProduct(deleteId) && handleClose()
  };

  useEffect(()=>{
	handleClose()
  }, [postData, bannerPostData])

  const handleGetProduct = async () => {
    await getAllProduct();
  };

//   const handleGetBanner = async () => {
//     await getAllBannerProduct();
//   };

//   useEffect(() => {
// 	handleGetBanner()
//   }, [bannerPostData]);

  useEffect(() => {
    handleGetProduct();
  }, [postData, deleteData, updateData]);

  return (
    <>

      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <span id="flash-container"></span>
                <div className="nk-block-between g-3">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title tb-ff">
                      All Products
                    </h3>
                  </div>
                  {/* .nk-block-head-content */}
                  <div className="nk-block-head-content">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
						onClick={() => setMobileAdd(prv => !prv)}
                        className={`btn btn-icon btn-trigger toggle-expand mr-n1 ${mobileAdd ? 'active' : ''}`}
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v" />
                      </a>
                      <div
                        className="toggle-expand-content"
                        style={{display: ` ${mobileAdd ? 'block' : 'none'}`}}
                      >
                        <ul className="nk-block-tools g-3">
                          <li>
                            <button
                              className="btn  tb-ff bg-paybond"
                              id="proceeder"
							  onClick={()=> setAddModal(true)}
                            >
                              <em className="icon ni ni-plus text-white" />{" "}
                              <span className="add-button text-white">
                                Add Product
                              </span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* .nk-block-head-content */}
                </div>
                {/* .nk-block-between */}
              </div>
              {/* .nk-block-head */}
              <div className="nk-block">
                <div className="card card-bordered card-stretch card-container">
                  <div className="card-inner-group">
                    <div className="card-inner p-0">
                      <table className="table table-tranx trans-table">
                        <thead className="table-header">
                          <tr className="tb-tnx-head tb-head-ctx">
                            <th className="tb-tnx-id tb-inner-tx">
                              <span className>Title</span>
                            </th>
                            <th className="tb-tnx-info tb-inner-tx">
                              <span className="tb-tnx-desc d-none d-sm-inline-block">
                                <span>price</span>
                              </span>
                              <span className="tb-tnx-date d-md-inline-block d-none">
                                <span className="d-none d-md-block">
                                  <span> Quantity</span>
                                  <span> Date</span>
                                </span>
                              </span>
                            </th>
                            <th className="tb-tnx-action tb-inner-tx">
                              <span>&nbsp;</span>
                            </th>
                          </tr>
                          {/* tb-tnx-item */}
                        </thead>
                        <tbody className="table-content">
						{
								loading 
								?
									[1,2,3,4,5].map((item, index)=>{
										return (
											
										<tr key={index} className="tb-tnx-item">
											<td className="tb-tnx-id">
											<span>
												<small
												style={{
													color: "royalblue",
													fontSize: 13,
													fontWeight: "bolder",
												}}
												>
												<Skeleton type='text' />
												</small>
											</span>
											</td>
											<td className="tb-tnx-info">
											<div className="tb-tnx-desc tb-tnx-size">
												<Skeleton type='text' />
											</div>
											<div className="tb-tnx-date">
												<span className="date">
													<Skeleton type='text' />
												</span>
												<span className="date">
													<Skeleton type='text' />
												</span>
											</div>
											</td>
											<td className="tb-tnx-action">
												<Skeleton type='text' />
											</td>
										</tr>
										)
									}) 
								:
								data.length < 1 ?
								
								<div className='child-container'>
									<div className='centered-content'>
										<div className='emptystate-container'>
											{/* <div>
												<div> */}
													<img src={emptyState} alt='no item found' />
													<p>No Product</p>
												{/* </div>
											</div> */}
										</div>
									</div>
								</div>
								:
								data?.map((product, index) =>{
									return (
										<tr key={index} className="tb-tnx-item">
											<td className="tb-tnx-id">
											<span>
												<small
												style={{
													fontSize: 13,
													fontWeight: "bolder",
												}}
												>
												{product.title}
												</small>
											</span>
											</td>
											<td className="tb-tnx-info">
											<div className="tb-tnx-desc tb-tnx-size">
												<span className="currency">₦</span><span className="title">{product.price}</span>
											</div>
											<div className="tb-tnx-date">
												<span className="date">
												<small>{product.quantity}</small>
												</span>
												<span className="date">
												<small>{`${(new Date(product?.createdAt)).toLocaleDateString('en-US', options)}`}</small>
												</span>
											</div>
											</td>
											<td className="tb-tnx-action">
												<div className="dropdown">
													<a className="text-soft dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em className="icon ni ni-more-h" /></a>
													<div className="dropdown-menu dropdown-menu-right ">
														<ul className="link-list-opt no-bdr">
															<li><Link to={`${_route._admin_product}/${product._id}`}><em className="icon ni ni-eye" /><span>View Product</span></Link></li>
															<li onClick={()=> {setEditItem(product); setEditAddModal(true)}}><a href="javascript:void(0)"><em className="icon ni ni-edit" /><span>Edit Product</span></a></li>
															<li onClick={()=> {setDeleteId(product._id); setDeleteModal(true)}}><a href="javascript:void(0)"><em className="icon ni ni-trash" /><span>Delete Product</span></a></li>
														</ul>
													</div>
												</div>
											</td>
										</tr>
							);
								})
						}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* .card-inner-group */}
                </div>
                
              </div>
              {/* .nk-block */}
            </div>
          </div>
        </div>
      </div>


	  
	  {addModal && (
        <ModalWithHeader
          handleClose={handleClose}
          showModal={addModal}
		  header='Add Product'
        >
          
		  <AddProduct handlePostCrypto={handleSave} loading={postLoading} />
        </ModalWithHeader>
      )}

	  	  
	{editModal && (
        <ModalWithHeader
          handleClose={handleClose}
          showModal={editModal}
		  header='Edit Product'
        >
          
		  <EditProductForm editCrypto={editItem} setEditCrypto={setEditItem}  handleUpdateCrypto={handleUpdate} loading={updateLoading} />
            {/* <div className="modal-footer bg-light">
              <span className="sub-text">
              </span>
            </div> */}
        </ModalWithHeader>
      )}

	  {deleteModal && (
        <ModalWithHeader
          handleClose={handleClose}
          showModal={deleteModal}
		  header='Are you sure?'
        >
			<div class="modal-body">
				<p>Do you really want to delete this product?
					This process cannot be reversed.</p>

			</div>
			<div class="modal-footer">
				<button onClick={()=> handleClose()} type="button" class="btn btn-primary" data-dismiss="modal">Cancel</button>

				<button onClick={() => handleDelete()} class="btn btn-danger">{deleteLoading ? <Spinnar /> : 'Delete'}
				</button>


			</div>
        </ModalWithHeader>
      )}
    </>
  );
}
