import myApi from "../../common/interceptors/apiinterceptor";
import {
  _getAllOrderTransaction,
  _getAllUnlimitedOrderTransaction,
  _getAllUserPaybillsTransaction,
  _getAllUnlimitedPaybillsTransaction,
} from "../../../network/network";

const Transactionservices = {
  getAllUnlimitedOrderTransaction: async (id, source) => {
    if (id) {
      return await myApi
        .post(
          `${_getAllOrderTransaction}`,
          { user: id },
          {
            cancelToken: source.token,
          }
        )
        .then(async (res) => {
          return res;
        });
    } else {
      return await myApi
        .get(`${_getAllUnlimitedOrderTransaction}`, {
          cancelToken: source.token,
        })
        .then(async (res) => {
          return res;
        });
    }
  },
  getAllUnlimitedPaybillsTransaction: async (id, source) => {
	if(id){
    return await myApi
      .get(`${_getAllUserPaybillsTransaction}`, {
        cancelToken: source.token,
        params: {
          user: id,
        },
      })
      .then(async (res) => {
        return res;
      });
	}else{
		return await myApi
	  .get(`${_getAllUnlimitedPaybillsTransaction}`, {
		cancelToken: source.token,
	  })
	  .then(async (res) => {
		return res;
	  });
	}
  },

  // upDateCrytoTransaction: async (data, id, source) => {
  // 	return await myApi.patch(`${_upDateCryptoTransaction}/${id}`, data, {
  // 	cancelToken: source.token,
  // 	}).then(async (res) => {
  // 	return res;
  // 	});

  // },
};

export default Transactionservices;
