import myApi from "./common/interceptors/apiinterceptor";
import { _updateAgent } from "../network/network";

// src/services/pushNotification.js
const CONFIG = {
  DBHost: "https://api-perrypay.carburant.io",
  publicVapidKey: 'BHF0vCoyKq1z6SOMcotUkUXMChON6EFiav0tUGksXLWMab8wLcHAH6EXk2oBGiHyiMgSS61juMF65wepSGxYAyg',
  serviceWorkerPath: '/service-worker.js'
};

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// const saveSubscription = async (subscription) => {
//   try {
//     const resp = await myApi.patch(`${_updateAgent}/${id}`, { web_push: subscription },  {
// 		cancelToken: source.token,
// 	  }).then(async (res) => {
// 		return res;
// 	  });
//     return resp.json();
//   } catch (error) {
//     console.error('Error saving subscription:', error);
//     throw error;
//   }
// };

const saveSubscription = async (subscription, id, source) => {
	try {
	  // Send the subscription to the API endpoint
	  const response = await myApi.patch(
		`${_updateAgent}/${id}`,
		{ web_push: subscription },
		{ cancelToken: source.token }
	  );
  
	  // Axios responses have data directly
	  return response.data;
	} catch (error) {
	  console.error('Error saving subscription:', error);
  
	  // Throw error so the caller can handle it appropriately
	  throw error;
	}
};

const requestNotificationPermission = async () => {
  const permission = await Notification.requestPermission();
  if (permission !== "granted") {
    throw new Error("Notification Permission is not granted");
  }
  return permission;
};

const subscribe = async (id, source) => {
  if (!('serviceWorker' in navigator)) {
    throw new Error('Service workers not supported in browser');
  }

  try {
    const register = await navigator.serviceWorker.register(CONFIG.serviceWorkerPath);
    const subscription = await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(CONFIG.publicVapidKey),
    });
    
    await saveSubscription(subscription, id, source);
    return subscription;
  } catch (error) {
    console.error('Error subscribing to push notifications:', error);
    throw error;
  }
};

export const initializePushNotifications = async (id, source) => {
  try {
    await requestNotificationPermission();
    const subscription = await subscribe(id, source);
	console.log("subscription", subscription)
    return subscription;
  } catch (error) {
    console.error('Error initializing push notifications:', error);
    throw error;
  }
};
