import { useEffect, useState } from "react";
import _route from "../../constants/routes";
import "./admin.css";
import AddProduct from "../../component/banner/addBanner";
import EditProductForm from "../../component/banner/editbanner";
// import useGetAllBanner from "../../hooks/banner/usegetbannerproduct";
// import usepostNotification from "../../hooks/banner/usecreatebannerproduct";
import useUpdateBanner from "../../hooks/banner/useupdatebanner";
import useDeleteBanner from "../../hooks/banner/usedeletebanner";
import Spinnar from "../../helpers/spinnar";
import emptyState from '../../assets/images/oops.png'
import { Link } from "react-router-dom";
import ModalWithHeader from "../../component/modalwithtitle"
import Skeleton from "../../component/skeletons/skeleton";
import usePostNotification from "../../hooks/notification/usecreatenotification";
import useGetAllNotification from "../../hooks/notification/usegetsupernotification";
import useGetAllAgent from "../../hooks/staff/usegetagent";
import AddNotification from "../../component/banner/addnotification";
import NotificationsList from "../../component/agent/notificationItem";

export default function ProductBanner() {
	const [addModal, setAddModal] = useState(false)
	const options = { month: 'long', year: 'numeric', day: 'numeric' };
	const [mobileAdd, setMobileAdd] = useState(false)
	const [editModal, setEditAddModal] = useState(false)
	const [deleteId, setDeleteId] = useState(null)
	const [deleteModal, setDeleteModal] = useState(false)
	const [editItem, setEditItem] = useState(null)
	const {getAllAgent, data: agentData, loading: agentLoading} = useGetAllAgent()
	const {getAllNotification, data: bannerData, loading} = useGetAllNotification();
	const {postNotification, data: postData, loading: postLoading} = usePostNotification();
	const {deleteBanner, data: deleteData, loading: deleteLoading} = useDeleteBanner();
	const {UpdateBanner, data: upDateData, loading: updateLoading} = useUpdateBanner();



  const handleClose = () => {
	setAddModal(false)
	setDeleteId(null)
	setEditItem(null)
	setDeleteModal(false)
	setEditAddModal(false)
  };

  const handleSave = (details) => {
	console.log(details)
    postNotification(details);
  };

  const handleUpdate = async (details) => {
    await UpdateBanner(details, editItem._id) && handleClose()
  };

  const handleDelete = async () => {
	await deleteBanner(deleteId) && handleClose()
  };

  useEffect(()=>{
	handleClose()
  }, [postData])



  const handleGetBanner = async () => {
    await getAllNotification("staffs");
  };

  const handleGetAllAgent = () => {
	getAllAgent()
  }

  useEffect(()=>{
	handleGetAllAgent()
  }, [])
  useEffect(() => {
	handleGetBanner()
  }, [postData, upDateData, deleteData]);


  return (
    <>

      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <span id="flash-container"></span>
                <div className="nk-block-between g-3">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title tb-ff">
                      Agent Announcement
                    </h3>
                  </div>
                  {/* .nk-block-head-content */}
                  <div className="nk-block-head-content">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
						onClick={() => setMobileAdd(prv => !prv)}
                        className={`btn btn-icon btn-trigger toggle-expand mr-n1 ${mobileAdd ? 'active' : ''}`}
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v" />
                      </a>
                      <div
                        className="toggle-expand-content"
                        style={{display: ` ${mobileAdd ? 'block' : 'none'}`}}
                      >
                        <ul className="nk-block-tools g-3">
                          <li>
                            <button
                              className="btn bg-paybond tb-ff"
                              id="proceeder"
							  onClick={()=> setAddModal(true)}
                            >
                              <em className="icon ni ni-plus text-white" />{" "}
                              <span className="add-button text-white">
                                Add Announcement
                              </span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* .nk-block-head-content */}
                </div>
                {/* .nk-block-between */}
              </div>
              {/* .nk-block-head */}
              <div className="nk-block">
    

				<NotificationsList for_who="staffs" notifications={bannerData.filter(item => item?.for_who?.toLowerCase() === 'staffs')} />
               
              </div>
              {/* .nk-block */}
            </div>
          </div>
        </div>
      </div>


	  
	  {addModal && (
        <ModalWithHeader
          handleClose={handleClose}
          showModal={addModal}
		  header='Add Announcement'
        >
          
		  <AddNotification title='Announcement' for_who='staffs' handlePostCrypto={handleSave} loading={postLoading} allUserLoading={agentLoading} allUserData={agentData}   />
		  <div className="modal-footer bg-light">
              <span className="sub-text">
                {/* This adds a cryptocurrency to the listings on the website */}
              </span>
            </div>
        </ModalWithHeader>
      )}

	  	  
	{editModal && (
        <ModalWithHeader
          handleClose={handleClose}
          showModal={editModal}
		  header='Edit Announcement'
        >
          
		  <EditProductForm editCrypto={editItem}  title='Announcement' setEditCrypto={setEditItem}  handleUpdateCrypto={handleUpdate} loading={updateLoading} />
            <div className="modal-footer bg-light">
              <span className="sub-text">
                {/* This adds a cryptocurrency to the listings on the website */}
              </span>
            </div>
        </ModalWithHeader>
      )}

	  {deleteModal && (
        <ModalWithHeader
          handleClose={handleClose}
          showModal={deleteModal}
		  header='Are you sure?'
        >
			<div class="modal-body">
				<p>Do you really want to delete this announcement?
					This process cannot be reversed.</p>

			</div>
			<div class="modal-footer">
				<button onClick={()=> handleClose()} type="button" class="btn btn-primary" data-dismiss="modal">Cancel</button>

				<button onClick={() => handleDelete(deleteId)} class="btn btn-danger">{deleteLoading ? <Spinnar /> : 'Delete'}
				</button>


			</div>
        </ModalWithHeader>
      )}
    </>
  );
}
