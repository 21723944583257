import { useEffect } from "react";
import moment from "moment";
import useGetSingleNotification from "../../hooks/notification/usegetsinglenotification"
import { useParams, useSearchParams } from "react-router-dom";
import Skeleton from "../../component/skeletons/skeleton";


export default function SupperSingleNotification() {
	const {getSingleNotification, data: notification, loading} = useGetSingleNotification()
	const {id, for_who} = useParams()
	const [searchParams] = useSearchParams();
	const forWho = searchParams.get("for_who"); // Extract `for_who`

	console.log(forWho)

	const handleGetNotification = async () =>{
		await getSingleNotification(id, forWho)
	}
	useEffect(()=>{
		handleGetNotification()
	},[])

console.log(notification)

	return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm mt-4">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h4 className="page-title cus-page-title">
					Notification
                    </h4>
                    <div className="nk-block-des">
                      <p>Notification Time: { loading ? <Skeleton type="text" /> : <span class="text-base">{ moment(notification?.createdAt).fromNow()}</span>}</p>
                    </div>
                  </div>
                  {/* .nk-block-head-content */}
                </div>
                {/* .nk-block-between */}
              </div>
              {/* .nk-block */}
              
			  <div className="nk-block">
					<div className="row gy-12">
						<div className="col-lg-12">
						<div className="nk-block-head">
							<div className="nk-block-head-content">
							<h5 className="nk-block-title title">{loading ? <Skeleton type="title" /> : notification?.title}</h5>
							{
							loading ?
							<div className="notification-image-con">
								<Skeleton />
							</div>
							:
							notification?.image ?
							<div className="notification-image-con">
								<img src={ notification?.image?.url} />
							</div>
							 : ""
						}
							<p>{loading ? <Skeleton type="text" /> : notification?.message}</p>
							</div>
						</div>{/* .nk-block-head */}
						</div>{/* .col */}
					</div>{/* .row */}
				</div>

			  {/* <div className="nk-block">
				
				<div className="row g-gs">
						<DashboardAnalysis
						title="Total Users"
						value="15500"
						increase="5"
						width='3'
						/>
						<DashboardAnalysis
						title="Legendary Users"
						value="500"
						increase="-5"
						width='3'
						/>
						<DashboardAnalysis
						title="Premium Users"
						value="1500"
						increase="5"
						width='3'
						/>
						<DashboardAnalysis
						title="Basic Users"
						value="100"
						increase="-5"
						width='3'
						/>
				</div>
			  </div> */}
              {/* .nk-block */}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
