import DashboardAnalysis from "../../component/cards/dashboardanalysis";
import { Link } from "react-router-dom";
import _route from "../../constants/routes";
import './admin.css'
import OrderTableList from "../../component/dashboard/userlist";
import AgentTableList from "../../component/agent/agenttablelist";
import { useSelector } from 'react-redux'
import { usePushNotification } from '../../hooks/push-notification-hook';
import useGetAnalysis from '../../hooks/user/usegetanalysis';
import { useEffect } from "react";
import useGetAllUser from "../../hooks/user/usegetuser";
import useGetAllOrderTransaction from "../../hooks/transaction/groceries/usegetallunlimitedordertransaction";
import UnlimitedCardTransactionList from "../../component/transaction/unlimitedgiftcardtransactionlist";
import UnLimitedPaybillsTransactionList from "../../component/transaction/unlimitedpaybillstransactionlist";
import NotificationsList from "../../component/agent/notificationItem";


export default function Dashboard() {
	const user = useSelector((state) => state.auth.user)
	const { isSubscribed, error, requestPermission } = usePushNotification();
	const {getAnalysis, data, loading} = useGetAnalysis()
	const {getAllUser, data: userData, loading: userLoading} = useGetAllUser()
	const {getAllOrderTransaction, data: groceryOrderData, loading: groceryOrderLoading} = useGetAllOrderTransaction()

	const requestUserPermission = ()=>{		
		console.log("dis is running")
		!isSubscribed && requestPermission()
	}

	const handleGetAnalysis = ()=>{
		// getAnalysis()
		getAllUser()
		getAllOrderTransaction()
	}

	useEffect(()=>{
		requestUserPermission()
	}, [])

	useEffect(()=>{
		handleGetAnalysis()
	}, [])
	return ( 
	<>
		<div className="nk-content ">
		<div className="container-fluid">
			<div className="nk-content-inner">
			<div className="nk-content-body">
				{/* <div className="nk-block-head nk-block-head-sm mt-4">
				<div className="nk-block-between">
					<div className="nk-block-head-content">
					<h4 className="page-title cus-page-title">Admin Overview</h4>
					<div className="nk-block-des">
						<p>An overview of your account, Enjoy the experience.</p>
					</div>
					</div>
				</div>
				</div> */}
				{/* .nk-block-head */}
				<div className="nk-block">
				<div className="row g-gs">
					<DashboardAnalysis
						title="Total Users"
						value={userData.length || 0}
						// increase="12.8"
						loading={userLoading}
					/>
					<DashboardAnalysis
						title="Revenue"
						value={0}
						increase="0"
						loading={false}
					/>
					<DashboardAnalysis
						title="Total Amount Pending"
						value={0}
						loading={false}
						increase="0"
					/>
				</div>
				{/* .row */}
				</div>
				{/* .nk-block */}
				<div className="nk-block">
				<div className="row g-gs">
					{/* .col */}
					<div className="col-md-8">
						<div className="card h-100">
							<div className="nk-ecwg nk-ecwg2">
							<div className="card-inner flex-grow-1 d-flex flex-column pe-0 ps-0">
								<div className="card-title-group mt-n1 mb-2 pe-3 pe-sm-4 ps-3 ps-sm-4">
								<div className="card-title">
									<h6 className="title card-title text-black">
									Paybills Transaction
									</h6>
								</div>
								
								</div>
								{/* <div className="mt-n2 flex-grow-1  px-3 px-sm-4"> */}
									<UnLimitedPaybillsTransactionList dashboard />
								{/* </div> */}
							</div>
							{/* .card-inner */}
							</div>
							{/* .nk-ecwg */}
						</div>
					</div>
					{/* .col */}
					<div className="col-md-4">
						<div className="card  h-100">
							<div className="nk-ecwg nk-ecwg2">
							<div className="card-inner flex-grow-1 d-flex flex-column p-0 ">
								{/* <div className="card-title-group mt-n1 mb-2 pe-3 pe-sm-4 ps-3 ps-sm-4"> */}
								{/* <div className="card-title">
									<h6 className="title card-title text-black">
									Quick Notification
									</h6>
								</div> */}
								{/* <Link to={_route._admin_agent} className="mb-0 text-primary">View All </Link> */}
								
								{/* </div> */}
								{/* <div className="mt-n2 flex-grow-1  px-3 px-sm-4"> */}
									<NotificationsList dashboard />
								{/* </div> */}
							</div>
							{/* .card-inner */}
							</div>
							{/* .nk-ecwg */}
						</div>
					</div>
					<div className={``}>
					<div className="card h-100">
						<div className="nk-ecwg nk-ecwg2">
						<div className="card-inner pe-0 ps-0">
							<div className="card-title-group mt-n1 mb-2 pe-3 pe-sm-4 ps-3 ps-sm-4">
							<div className="card-title">
								<h6 className="title card-title text-black">Groceries Transaction</h6>
							</div>
							<Link to={_route._admin_transaction} className="mb-0 text-primary text-paybond">View All </Link>
							</div>
							<UnlimitedCardTransactionList dashboard />
						</div>
						{/* .card-inner */}
						</div>
						{/* .nk-ecwg */}
					</div>
					{/* .card */}
					</div>
					{/* .col */}
				</div>
				{/* .row */}
				</div>
				{/* .nk-block */}
			</div>
			</div>
		</div>
		</div>


	</>

  );
}
