// Footer for dashboard

export default function _footer() {
	return (
		<div className="nk-footer">
			<div className="container-fluid">
				<div className="nk-footer-wrap">
					<div className="nk-footer-copyright"> © {new Date().getFullYear()} Paybond. Made by <a href="https://carburant.io" target="_blank">Carburant.io</a>
					</div>
				</div>
			</div>
		</div>
	);
}
