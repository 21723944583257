export const _logInUser = process.env.REACT_APP_BASE_URL + "/staff/auth"
export const _logOutUser = process.env.REACT_APP_BASE_URL + "/staff/logout"
export const _getAllUser = process.env.REACT_APP_BASE_URL + "/user/get/all"
export const _getSingleUser = process.env.REACT_APP_BASE_URL + "/user/get"
export const _getAllUserPaybillsTransaction = process.env.REACT_APP_BASE_URL + "/transaction/bills/history"
export const _getAllUnlimitedPaybillsTransaction = process.env.REACT_APP_BASE_URL + "/transaction/bills/all/nolimit"
export const _getAllOrderTransaction = process.env.REACT_APP_BASE_URL + "/order/get/all"
export const _getAllUnlimitedOrderTransaction = process.env.REACT_APP_BASE_URL + "/order/get/all/nolimit"
export const _GetUserWallet = process.env.REACT_APP_BASE_URL + "/wallet/balance"
export const _UpDateUserWallet = process.env.REACT_APP_BASE_URL + "/wallet/nolimit"
export const _getAllProduct = process.env.REACT_APP_BASE_URL + "/groceries/get/all"
export const _updateProduct = process.env.REACT_APP_BASE_URL + "/groceries/update"
export const _postProduct = process.env.REACT_APP_BASE_URL + "/groceries/add"
export const _getSingleProduct = process.env.REACT_APP_BASE_URL + "/groceries/get"
export const _deleteProduct = process.env.REACT_APP_BASE_URL + "/groceries/remove"
export const _getSingleOrder = process.env.REACT_APP_BASE_URL + "/order/get"
export const _getAllAgent = process.env.REACT_APP_BASE_URL + "/staff/get/all"
export const _getAllLocation = process.env.REACT_APP_BASE_URL + "/store/delivery-state/get"
export const _addLocation = process.env.REACT_APP_BASE_URL + "/store/delivery-state/add"
export const _upDateLocation = process.env.REACT_APP_BASE_URL + "/store/delivery-state/update"
export const _setnewpassword = process.env.REACT_APP_BASE_URL + "/auth/change-password"




export const _updateWallet = process.env.REACT_APP_BASE_URL + "/manager/perrypoint/assign"
export const _getUserWallet = process.env.REACT_APP_BASE_URL + "/transaction/perrypoint/get"
// export const _verifyOtp = process.env.REACT_APP_BASE_URL + "/auth/verify-otp"
// export const _resendOtp = process.env.REACT_APP_BASE_URL + "/auth/verify-email"
export const _postAgent = process.env.REACT_APP_BASE_URL + "/staff/add"
export const _getAllChat = process.env.REACT_APP_BASE_URL + "/chat/get/all"
export const _getAnalysis = process.env.REACT_APP_BASE_URL + "/analytics/app"
export const _updateAgent = process.env.REACT_APP_BASE_URL + "/staff/update"
export const _getSingleAgent = process.env.REACT_APP_BASE_URL + "/staff/get"
export const _deleteAgent = process.env.REACT_APP_BASE_URL + "/staff/remove"
export const _UpdateUser = process.env.REACT_APP_BASE_URL + "/user/update"
export const _getAllBanner = process.env.REACT_APP_BASE_URL + "/manager/banner/get/all"
export const _getSingleBanner = process.env.REACT_APP_BASE_URL + "/manager/banner/get"
export const _postBanner = process.env.REACT_APP_BASE_URL + "/manager/banner/add"
export const _updateBanner = process.env.REACT_APP_BASE_URL + "/manager/banner/update"
export const _deleteBanner = process.env.REACT_APP_BASE_URL + "/manager/banner/remove"
export const _getAllCrypto = process.env.REACT_APP_BASE_URL + "/crypto/get/all"
export const _postCrypto = process.env.REACT_APP_BASE_URL + "/crypto/add"
export const _upDateCrypto = process.env.REACT_APP_BASE_URL + "/crypto/update"
export const _deleteCrypto = process.env.REACT_APP_BASE_URL + "/crypto/remove"
export const _getAllCategoryCard = process.env.REACT_APP_BASE_URL + "/card/category/get/allarr"
export const _getSingleCategoryCard = process.env.REACT_APP_BASE_URL + "/card/category/get"
export const _postCategoryCard = process.env.REACT_APP_BASE_URL + "/card/category/add"
export const _upDateCategoryCard = process.env.REACT_APP_BASE_URL + "/card/category/update"
export const _deleteCategoryCard = process.env.REACT_APP_BASE_URL + "/card/category/remove"
export const _getAllCountryCard = process.env.REACT_APP_BASE_URL + "/card/category/country/get/allarr"
export const _getSingleCountryCard = process.env.REACT_APP_BASE_URL + "/card/category/country/get"
export const _postCountryCard = process.env.REACT_APP_BASE_URL + "/card/category/country/add"
export const _upDateCountryCard = process.env.REACT_APP_BASE_URL + "/card/category/country/update"
export const _deleteCountryCard = process.env.REACT_APP_BASE_URL + "/card/category/country/remove"
export const _getAllCountry_Card = process.env.REACT_APP_BASE_URL + "/card/category/country/giftcard/get/allarr"
export const _postCountry_Card = process.env.REACT_APP_BASE_URL + "/card/category/country/giftcard/add"
export const _upDateCountry_Card = process.env.REACT_APP_BASE_URL + "/card/category/country/giftcard/update"
export const _deleteCountry_Card = process.env.REACT_APP_BASE_URL + "/card/category/country/giftcard/remove"
export const _getAllUnlimitedCryptoTransaction = process.env.REACT_APP_BASE_URL + "/transaction/crypto/get/all/nolimit"
export const _upDateCryptoTransaction = process.env.REACT_APP_BASE_URL + "/transaction/crypto/update"
export const _getAllCardTransaction = process.env.REACT_APP_BASE_URL + "/transaction/giftcard/get/all"
export const _getAllUnlimitedCardTransaction = process.env.REACT_APP_BASE_URL + "/transaction/giftcard/get/all/nolimit"
export const _upDateCardTransaction = process.env.REACT_APP_BASE_URL + "/transaction/giftcard/update"
export const _getAllPerryPointTransaction = process.env.REACT_APP_BASE_URL + "/transaction/perrypoint/get/all"
export const _getAllUnlimitedPerryPointTransaction = process.env.REACT_APP_BASE_URL + "/transaction/perrypoint/get/all/nolimit"
export const _upDatePerryPointTransaction = process.env.REACT_APP_BASE_URL + "/transaction/perrypoint/update"
export const _postSetting = process.env.REACT_APP_BASE_URL + "/manager/settings/add"
export const _getAllSetting = process.env.REACT_APP_BASE_URL + "/manager/settings/get"
export const _updateSetting = process.env.REACT_APP_BASE_URL + "/manager/settings/update"
export const _deleteSetting = process.env.REACT_APP_BASE_URL + "/manager/settings/remove"
export const _postAcademy = process.env.REACT_APP_BASE_URL + "/course/add"
export const _getAllAcademy = process.env.REACT_APP_BASE_URL + "/course/get/all"
export const _getSingleAcademy = process.env.REACT_APP_BASE_URL + "/course/get"
export const _deleteAcademy = process.env.REACT_APP_BASE_URL + "/course/remove"
export const _updateAcademy = process.env.REACT_APP_BASE_URL + "/course/update"
export const _postQuiz = process.env.REACT_APP_BASE_URL + "/quiz/add"
export const _getAllQuiz = process.env.REACT_APP_BASE_URL + "/quiz/get/all"
export const _getSingleQuiz = process.env.REACT_APP_BASE_URL + "/quiz/get"
export const _deleteQuiz = process.env.REACT_APP_BASE_URL + "/quiz/remove"
export const _updateQuiz = process.env.REACT_APP_BASE_URL + "/quiz/update"
export const _getAllOrder = process.env.REACT_APP_BASE_URL + "/order/get/all/nolimit"
export const _postOrder = process.env.REACT_APP_BASE_URL + "/Order/add"
export const _upDateOrder = process.env.REACT_APP_BASE_URL + "/order/update"
export const _deleteOrder = process.env.REACT_APP_BASE_URL + "/order/remove"
export const _getAllNotification = process.env.REACT_APP_BASE_URL + "/notification/get/all"
export const _getAllSuperNotification = process.env.REACT_APP_BASE_URL + "/notification/get/all/nolimit"
export const _getSingleNotification = process.env.REACT_APP_BASE_URL + "/notification/get"
export const _getSingleSuperNotification = process.env.REACT_APP_BASE_URL + "/notification/get/nolimit"
export const _postNotification = process.env.REACT_APP_BASE_URL + "/notification/send"
export const _updateNotification = process.env.REACT_APP_BASE_URL + "/notification/seen"
export const _deleteNotification = process.env.REACT_APP_BASE_URL + "/notification/remove"
