import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PaybillsTransactionList from "../../component/transaction/unlimitedpaybillstransactionlist";
import CardTransactionList from "../../component/transaction/unlimitedgiftcardtransactionlist";
import PerryPointTransactionList from "../../component/transaction/unlimitedperrypointtransactionlist";


export default function Transaction() {
	const [index, setIndex] = useState(0)


	return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* .nk-block */}
              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-md-12">
                    <div className="card h-100 rounded-5 px-2 py-3">
                      <div className="nk-ecwg nk-ecwg2">
                        <div className="card-inner pe-0 ps-0">
                          <div className="card-title-group titlt-tab mt-n1 mb-2 pe-3 pe-sm-4 ps-3 ps-sm-4">
                            <div className="card-title">
                              <h6 className="title fw-semibold fs-4 card-title text-black">
                                {index === 0 && 'Paybills Transactions'}
                                {index === 1 && 'Groceries Transactions'}
                              </h6>
                            </div>
                            {/* <p className="mb-0 text-primary">View All </p> */}
                            <div className="card-tools">
                              <span
								onClick={() => setIndex(0)}
                                className={`xo-toggle left ${index === 0 ? 'bg-paybond text-white' : "text-paybond"}`}
                                style={{ cursor: "pointer" }}
                              >
                                Paybills
                              </span>
                              <span
								onClick={() => setIndex(1)}
                                className={`xo-toggle right ${index === 1 ? 'bg-paybond text-white' : "text-paybond"}`}
                                style={{ cursor: "pointer" }}
                              >
                                Groceries
                              </span>
                            </div>
                          </div>
                          {index === 0 && <PaybillsTransactionList />}
                          {index === 1 && <CardTransactionList />}
                        </div>
                        {/* .card-inner */}
                      </div>
                      {/* .nk-ecwg */}
                    </div>
                    {/* .card */}
                  </div>
                  {/* .col */}
                </div>
              </div>
              {/* .nk-block */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
