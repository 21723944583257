import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _route from "../../constants/routes";
import './admin.css'
import usePostAgent from "../../hooks/staff/usecreateagent";
import Spinnar from "../../helpers/spinnar";


export default function AddAgent() {
	const {postAgent, data, loading} = usePostAgent()
	const navigate = useNavigate()
	const [agentDetails, setAgentDeatails] = useState({
		lastname: '',
		username: '',
		password: '',
		firstname: '',
		phone_no: '',
		address: '',
		country: '',
		state: '',
		city: '',
		permission: '',
	})


	const handleSubmit = async (e)=>{
		e.preventDefault()
		postAgent(agentDetails)
	}

	useEffect(()=>{
		if(data){
			navigate(_route._admin_agent)
		}
	}, [data])
	


	return (
    <>
      <div className="nk-content tb-ff">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title tb-ff">
                      Create Employees / Staff
                    </h3>
                    <div className="nk-block-des text-soft">
                      <p>
                        Kindly Fill this form with the appropriate staff details
                        and permissions required
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block nk-block-lg">
                <div className="card card-bordered">
                  <div className="card-inner">
                    <div className="card-head">
                      <h5 className="card-title tb-ff">Staff Details</h5>
                    </div>
                    <form
                      className="section general-info"
					  onSubmit={handleSubmit}
                    >
                      <div className="row g-3">
                        <div className="col-lg-6 ">
                          <div className="form-group ">
                            <label className="form-label " htmlFor="name">
                              First Name
                            </label>
                            <div className="form-control-wrap ">
                              <input
                                type="text "
                                className="form-control "
                                name="firstname"
                                placeholder="Christain"
								value={agentDetails.firstname}
								onChange={(e)=> setAgentDeatails(prv => ({...prv, firstname: e.target.value}))}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="form-group ">
                            <label className="form-label " htmlFor="name-2 ">
                              Last Name
                            </label>
                            <div className="form-control-wrap ">
                              <input
                                type="text "
                                className="form-control "
                                name="lastname"
                                placeholder="Dior"
								value={agentDetails.lastname}
								onChange={(e)=> setAgentDeatails(prv => ({...prv, lastname: e.target.value}))}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="form-group ">
                            <label
                              className="form-label "
                              htmlFor="email-address-1 "
                            >
                              Email address
                            </label>
                            <div className="form-control-wrap ">
                              <input
                                type="text "
                                className="form-control "
                                name="username"
                                placeholder="jane@you.com"
								value={agentDetails.username}
								onChange={(e)=> setAgentDeatails(prv => ({...prv, username: e.target.value}))}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="form-group ">
                            <label
                              className="form-label "
                              htmlFor="phone-no-1 "
                            >
                              Phone No
                            </label>
                            <div className="form-control-wrap ">
                              <input
                                type="tel "
                                className="form-control "
                                name="phone"
                                placeholder='08090902020'
								value={agentDetails.phone_no}
								onChange={(e)=> setAgentDeatails(prv => ({...prv, phone_no: e.target.value}))}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 ">
                          <div className="form-group ">
                            <label
                              className="form-label "
                            >
                              Password
                            </label>
                            <div className="form-control-wrap ">
                              <input
                                type="password"
                                className="form-control "
                                name="password"
								value={agentDetails.password}
								onChange={(e)=> setAgentDeatails(prv => ({...prv, password: e.target.value}))}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 ">
                          <label className="form-label " htmlFor="default-06 ">
                            Position
                          </label>
                          <div className="form-group">
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
							  value={agentDetails.permission}
							  onChange={(e)=> setAgentDeatails(prv => ({...prv, permission: e.target.value}))}
                              name="permissions"
                            >
								<option value='' hidden></option>
                              <option value="SUPER_ADMIN">
                                Supper Admin
                              </option>
                              <option value="AGENT">Agent</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4 ">
                          <div className="form-group ">
                            <label
                              className="form-label "
                            >
                              Address
                            </label>
                            <div className="form-control-wrap ">
                              <input
                                type="text"
                                className="form-control "
                                name="address"
								value={agentDetails.address}
								onChange={(e)=> setAgentDeatails(prv => ({...prv, address: e.target.value}))}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 ">
                          <div className="form-group ">
                            <label
                              className="form-label "
                            >
                              City
                            </label>
                            <div className="form-control-wrap ">
                              <input
                                type="text"
                                className="form-control "
                                name="city"
								value={agentDetails.city}
								onChange={(e)=> setAgentDeatails(prv => ({...prv, city: e.target.value}))}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 ">
                          <div className="form-group ">
                            <label
                              className="form-label "
                            >
                              State
                            </label>
                            <div className="form-control-wrap ">
                              <input
                                type="text"
                                className="form-control "
                                name="State"
								value={agentDetails.state}
								onChange={(e)=> setAgentDeatails(prv => ({...prv, state: e.target.value}))}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 ">
                          <div className="form-group ">
                            <label
                              className="form-label "
                            >
                              Country
                            </label>
                            <div className="form-control-wrap ">
                              <input
                                type="text"
                                className="form-control "
                                name="country"
								value={agentDetails.country}
								onChange={(e)=> setAgentDeatails(prv => ({...prv, country: e.target.value}))}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 ">
                          <div className="form-group ">
                            <button
                              type="submit "
                              className="btn btn-lg btn-primary bg-paybond tb-ff"
                            >
                              { loading ? <Spinnar /> : 'Create Staff'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

	 
    </>
  );
}
