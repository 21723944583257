import { useState } from "react";
import Spinnar from "../spinnar";
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Checkbox } from '@mui/material';

export default function AddNotification({ handlePostCrypto, allUserLoading, allUserData, for_who, loading, page, type, title }) {
	const theme = useTheme();
	const ALL_AGENTS_ID = 'all-agents';
	const [personName, setPersonName] = useState([ALL_AGENTS_ID]);
	const [crypto, setCrypto] = useState({
		title: "",
		description: "",
		logo: "",
		for_who: for_who
	});

	// Function to handle file change
	const handleFileChange = (e) => {
		const file = e.target.files[0]; // Get the selected file
		const reader = new FileReader();

		reader.onload = (event) => {
		// When file is read, update the state with the file contents
		setCrypto((prevState) => ({
			...prevState,
			logo: file, // Use event.target.result to get the file contents as a data URL
		}));
		const logoLabel = document.getElementById("logoLabel");
		console.log(logoLabel);
		if (logoLabel) {
			console.log("label");
			logoLabel.innerText = file.name;
		}
		};

		console.log(crypto);
		reader.readAsDataURL(file); // Read the file as a data URL
	};


	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(crypto)
		const formData = new FormData();

		formData.append("title", crypto.title);
		formData.append("message", crypto.description);
		formData.append("image", crypto.logo);
		formData.append("for_who", crypto.for_who);
		// formData.append(`${for_who}[]`, personName);
		  // Directly append each ID to staffs[]
		if(!personName.includes(ALL_AGENTS_ID)){
			personName.forEach(id => {
				formData.append(`${for_who}[]`, id);
			});
		}

		for (let entry of formData.entries()) {
		// entry is an array [key, value]
		const [key, value] = entry;

		// Log the key and value to see details
		console.log(`Key: ${key}, Value: ${value}`);
		}
		handlePostCrypto(formData);
	};

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
	PaperProps: {
		style: {
		maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
		width: 250,
		},
	},
	};


	function getStyles(name, personName, theme) {
	return {
		fontWeight: personName.includes(name)
		? theme.typography.fontWeightMedium
		: theme.typography.fontWeightRegular,
	};
	}



	const handleChange = (event) => {
		const {
		  target: { value },
		} = event;
	  
		console.log("event", event.target.value)
	  
		// Determine the new selection
		let newSelection = typeof value === 'string' ? value.split(',') : value;
	  
		console.log("newSelection before processing", newSelection);
	  
		// Check the last item in the selection
		const lastItem = newSelection[newSelection.length - 1];
	  
		if (lastItem === ALL_AGENTS_ID) {
		  console.log("1111")
		  console.log("testing", newSelection)
		  // If "All Agents" is the last selected item, only keep "All Agents"
		  newSelection = [ALL_AGENTS_ID];
		} else {
		  console.log("2222222")
		  console.log("tting", newSelection)
		  // If the last item is not "All Agents", remove "All Agents" if present
		  newSelection = newSelection.filter(item => item !== ALL_AGENTS_ID);
		}
	  
		console.log("newSelection after processing", newSelection);
	  
		setPersonName(newSelection);
	};


console.log("personName", personName)
  return (
    <>
      <div className="modal-body">
        <form className="form-validate is-alter" onSubmit={handleSubmit}>
			<div className="form-group">
				<label className="form-label" htmlFor="full-name">
				Title
				</label>
				<div className="form-control-wrap">
				<input
					required
					type="text"
					value={crypto.title}
					onChange={(e) =>
					setCrypto((prv) => ({ ...prv, title: e.target.value }))
					}
					className="form-control"
					id="full-name"
				/>
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="email-address">
              Description
            </label>
            <div className="form-control-wrap">
              <textarea
                required
				rows={5}
                value={crypto.description}
                onChange={(e) =>
                  setCrypto((prv) => ({ ...prv, description: e.target.value }))
                }
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              {title} Image
            </label>
            <div className="form-control-wrap">
              <div className="custom-">
                <input
                  required
                  type="file"
                  name="logo"
                  onChange={(e) => handleFileChange(e)}
                  // className="custom-file-input"
                  id="customLogoFile"
                />
                <label
                  id="logoLabel"
                  className="custom-file-label"
                  htmlFor="customLogoFile"
                >
                  Choose file
                </label>
              </div>
            </div>
          </div>
		  <div className="form-group">
    <FormControl sx={{ m: 1, width: "100%" }}>
      <InputLabel id="demo-multiple-name-label">Select Receivers</InputLabel>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={personName}
        onChange={handleChange}
        input={<OutlinedInput label="Select Receivers" />}
        MenuProps={MenuProps}
      >
        {/* Add All Agents option */}
        <MenuItem key={ALL_AGENTS_ID} value={ALL_AGENTS_ID}>
          <Checkbox checked={personName.includes(ALL_AGENTS_ID)} />
          All Receivers
        </MenuItem>
        
        {/* Existing user map */}
        {allUserData.map((name) => (
          <MenuItem
            key={name._id}
            value={name._id}
            style={getStyles(name, personName, theme)}
          >
            <Checkbox checked={personName.includes(name._id)} />
            {`${name?.firstname} ${name?.lastname}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
		  {/* {type === 'home' && <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              Page
            </label>
            <div className="form-control-wrap">
              <select
				value={crypto.page}
				onChange={(e) =>
					setCrypto((prv) => ({ ...prv, page: e.target.value }))
				}
				className="form-control"
			  >
				<option value='' hidden></option>
				<option value='chat'>Chat</option>
				<option value='store'>Shop</option>
				<option value='academy'>Academy</option>
				<option value='electricity'>Pay bills</option>
			  </select>
            </div>
          </div>} */}
          <div className="form-group">
            <button type="submit" className="btn btn-lg btn-primary tb-ff">
              {loading ? <Spinnar /> : `Add ${title}`}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
