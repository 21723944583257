import { useState, useEffect } from 'react'
import Spinnar from "../spinnar";

export default function EditProductForm({editCrypto, page, type, title, setEditCrypto, loading, handleUpdateCrypto}) {
	const [crypto, setCrypto] = useState({
		name: editCrypto ? editCrypto?.name : '',
		description: editCrypto ? editCrypto?.description : '',
		logo: '',
		type: editCrypto ? editCrypto.type : '',
		page: editCrypto ? editCrypto.page : '',
		show_to: editCrypto.show_to


	})
	
	console.log(editCrypto)
	useEffect(()=>{
		const logoLabel = document.getElementById('logoLabel');
		logoLabel.innerText = editCrypto?.image?.filename;
	}, [])

	// Function to handle file change
	const handleFileChange = (e) => {
		const file = e.target.files[0]; // Get the selected file
		const reader = new FileReader();
	
		reader.onload = (event) => {
		// When file is read, update the state with the file contents
		setCrypto(prevState => ({
			...prevState,
			logo: file // Use event.target.result to get the file contents as a data URL
		}));
		const logoLabel = document.getElementById('logoLabel');
		console.log(logoLabel)
		if (logoLabel) {
			console.log('label')
			logoLabel.innerText = file.name;
		}
		};
	
		console.log(crypto)
		reader.readAsDataURL(file); // Read the file as a data URL
	};


	const handleSubmit = (e) => {
	  e.preventDefault();
	  console.log(crypto);
	  const formData = new FormData();
  
	  type === 'notification' && formData.append("name", crypto.name);
	  type === 'notification' && formData.append("description", crypto.description);
	  crypto.logo !== '' && formData.append("image", crypto.logo);
	  formData.append("type", crypto.type);
	  crypto.show_to !== undefined || crypto.show_to !== null && formData.append("show_to", crypto.show_to);
	  editCrypto.page !== undefined && formData.append("page", crypto.page);
  
	  for (let entry of formData.entries()) {
		// entry is an array [key, value]
		const [key, value] = entry;
  
		// Log the key and value to see details
		console.log(`Key: ${key}, Value: ${value}`);
	  }
	  
	  handleUpdateCrypto(formData, editCrypto._id);
	};

  return (
	<>
	  
	  <div className="modal-body">
              <form
                className="form-validate is-alter"
				onSubmit={handleSubmit}
              >
				{crypto.type === 'notification' && <div className="form-group">
					<label className="form-label" htmlFor="full-name">
					Title
					</label>
					<div className="form-control-wrap">
					<input
						required
						type="text"
						value={crypto.name}
						onChange={(e) => setCrypto(prv => ({...prv, name:e.target.value}))}
						className="form-control"
						id="full-name"
					/>
					</div>
				</div>}
                {crypto.type === 'notification' && <div className="form-group">
                  <label className="form-label" htmlFor="email-address">
                    Description
                  </label>
                  <div className="form-control-wrap">
                    <textarea
					  required
					  rows={5}
					  value={crypto.description}
					  onChange={(e) => setCrypto(prv => ({...prv, description:e.target.value}))}
                      className="form-control"
                    />
                  </div>
                </div>}
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    {title} Image
                  </label>
                  <div className="form-control-wrap">
                    <div className="custom-">
                      <input
                        type="file"
                        name="logo"
						onChange={(e) => handleFileChange(e)}
                        // className="custom-file-input"
                        id="customLogoFile"
                      />
                      <label id='logoLabel' className="custom-file-label" htmlFor="customLogoFile">
                        {editCrypto?.logo?.filename ? editCrypto?.logo?.filename : 'Choose file'}
                      </label>
                    </div>
                  </div>
                </div>
		  {crypto.type === 'home' && <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              Page
            </label>
            <div className="form-control-wrap">
              <select
				value={crypto.page}
				onChange={(e) =>
					setCrypto((prv) => ({ ...prv, page: e.target.value }))
				}
				className="form-control"
			  >
				<option value='' hidden></option>
				<option value='chat'>Chat</option>
				<option value='store'>Shop</option>
				<option value='academy'>Academy</option>
				<option value='electricity'>Pay bills</option>
			  </select>
            </div>
          </div>}
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary tb-ff"
                  >
                     {loading ? <Spinnar /> : `Update ${title}`}
                  </button>
                </div>
              </form>
            </div>
	</>
  )
}
